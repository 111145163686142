/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  matchPath,
} from 'react-router-dom'
import AOS from 'aos'
import 'aos/src/sass/aos.scss'

import './Wrapper.scss'
import { PATH } from '../../constants'

import { Homepage } from '../../../homepage/views'
import { Footer, Navbar } from '../../components'
import { Partner } from '../../../partner/views'
import { Promo, DetailPromo } from '../../../promo/views'
import { DanaTunai } from '../../../danaTunai/views'
import { StarXFlyer } from '../../../starxFlyer/views'
import { PageTrackGTM, PageTrackingGA, ScrollToTop } from '../../utils'
import Product from '../../../product/views/Product'
import { Error } from '../../../error/views'
import {
  ServiceProvider,
  ServiceProviderAll,
} from '../../../serviceProvider/views'
import { FAB } from '../../../../components'
import { DeepLink } from '../../../deepLink/views'
import {
  BPKBOwner,
  BPKBOwnerRevise,
  BPKBOwnerSpouse,
  BPKBThankYou,
} from '../../../bpkbOwner/views'
import { LivenessDetection } from '../../../livenessDetection/views'
import { PrivacyPolicy } from '../../../privacyPolicy/views'
import PromoDanaTunai from '../../../promo/views/PromoDanaTunai/PromoDanaTunai'
import PromoMobilPenumpang from '../../../promo/views/PromoNewCar/PromoMobilPenumpang'
import PromoMobilNiaga from '../../../promo/views/PromoMobilNiaga'
import PromoModalUsaha from '../../../promo/views/PromoModalUsaha'

const Wrapper: React.FC = () => {
  const [showFAB, setShowFAB] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    AOS.init()
    AOS.refresh()

    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setShowFAB(true)
      } else {
        setShowFAB(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  })

  const pathname = window.location.pathname
  const hiddenWrapper = [
    matchPath(pathname, PATH.BPKB_OWNER)?.isExact,
    matchPath(pathname, PATH.BPKB_OWNER_SPOUSE)?.isExact,
    matchPath(pathname, PATH.BPKB_THANK_YOU)?.isExact,
    matchPath(pathname, PATH.BPKB_OWNER_REVISE)?.isExact,
    matchPath(pathname, PATH.LIVENESS)?.isExact,
    matchPath(pathname, PATH.STARX_FLYER)?.isExact,
  ]

  return (
    <div className="wrapper">
      <Router>
        <ScrollToTop />
        {!hiddenWrapper.includes(true) && (
          <div className="wrapper-navbar">
            <Navbar />
          </div>
        )}
        <div className="wrapper-content">
          <Switch>
            <Route exact path={PATH.HOME} component={Homepage} />
            <Route exact path={PATH.PARTNER} component={Partner} />
            <Route exact path={PATH.PROMO} component={Promo} />
            <Route exact path={PATH.STARX_FLYER} component={StarXFlyer} />
            <Route
              exact
              path={PATH.PROMO_NEW_CAR}
              component={PromoMobilPenumpang}
            />
            <Route
              exact
              path={PATH.PROMO_TRADE_CAR}
              component={PromoMobilNiaga}
            />
            <Route
              exact
              path={PATH.PROMO_DANA_TUNAI}
              component={PromoDanaTunai}
            />
            <Route
              exact
              path={PATH.PROMO_MODAL_USAHA}
              component={PromoModalUsaha}
            />
            <Route exact path={PATH.PROMO_DETAIL} component={DetailPromo} />
            <Route exact path={PATH.PRODUCT} component={Product} />
            <Route exact path={PATH.PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route
              exact
              path={PATH.SERVICE_PROVIDER}
              component={ServiceProvider}
            />
            <Route
              exact
              path={PATH.SERVICE_PROVIDER_ALL}
              component={ServiceProviderAll}
            />
            <Route exact path={PATH.DANA_TUNAI} component={DanaTunai} />
            <Route exact path={PATH.BPKB_OWNER} component={BPKBOwner} />
            <Route
              exact
              path={PATH.BPKB_OWNER_SPOUSE}
              component={BPKBOwnerSpouse}
            />
            <Route
              exact
              path={PATH.BPKB_OWNER_REVISE}
              component={BPKBOwnerRevise}
            />
            <Route exact path={PATH.BPKB_THANK_YOU} component={BPKBThankYou} />
            <Route exact path={PATH.LIVENESS} component={LivenessDetection} />
            <Route exact path={PATH.DEEP_LINK} component={DeepLink} />

            <Route path={PATH.ERROR_PAGE} component={Error} />
            <Route component={Error} />
          </Switch>
        </div>
        {!hiddenWrapper.includes(true) && (
          <div className="wrapper-footer">
            <Footer />
          </div>
        )}
        {showFAB && <FAB onClick={scrollToTop} />}

        <PageTrackingGA />
        <PageTrackGTM />
      </Router>
    </div>
  )
}

export default Wrapper
