/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RootState } from '../store'
import { createSelector } from '@reduxjs/toolkit'

export const bpkbOwnerDetail = (state: RootState) => state.bpkbOwner

export const bpkbOwnerSelector = createSelector(
  bpkbOwnerDetail,
  (state) => state,
)
