/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'
import { Env } from '../../config/Config'

const http = axios.create({
  baseURL: Env.api_services.file,
  headers: {
    'Content-type': 'multipart/form-data',
  },
})

const create = ({
  ...params
}: {
  app_name: string
  profile_name: string
  tags: string
  files: any
}) => {
  const formData = new FormData()
  formData.append('file', params.files)
  return http.post(
    `/files/${params.app_name}/${params.profile_name}/uploads`,
    formData,
    {
      params: {
        tags: params.tags,
      },
    },
  )
}

const FileService = {
  create,
}
export default FileService
