//#region IMPORT
import React, { useCallback, useEffect, useState } from 'react'
import Axios from 'axios'

import { Header } from '../../../homepage/components'
import { Breadcrumb } from '../../../homepage/components/Breadcrumb'
import {
  CardPromo,
  CardPromoSkeleton,
  CardPromoSkeletonContainer,
} from '../../components/CardPromo'
import FooterPromo from '../../components/FooterPromo'
import t from '../../../../lang'
import '../Promo/Promo.scss'

import { PromoList, PROMO_API, FILTER_LIST } from '../../constants'
import InfiniteScroll from 'react-infinite-scroll-component'
import { mdiChevronRight } from '@mdi/js'
import { useDocumentTitle } from '../../../wrapper/utils'
import { useHistory } from 'react-router-dom'
import Pills from '../../../../components/Pills'
import Skeleton from 'react-loading-skeleton'
//#endregion

interface BreadcrumbInt {
  title: string
  link: string
  is_active: boolean
}

interface PromoParams {
  limit: number
  offset: number
  status: string
  promo_type?: string
}

interface FilterTabAvailabilityInterface {
  [key: string]: boolean
}

const PromoDanaTunai: React.FC = () => {
  const [breadcrumbList, setBreadcrumbList] = useState<BreadcrumbInt[]>([])
  const [loading, setLoading] = useState(false)
  const [length, setLength] = useState(0)
  const [next, setNext] = useState(0)
  const history = useHistory()
  const filterTabList = FILTER_LIST

  const getPromo = ({ type = '' }) => {
    // eslint-disable-next-line prefer-const
    let params: PromoParams = {
      limit: 6,
      offset: next,
      status: 'published',
    }
    type && (params.promo_type = type)

    return Axios.get(PROMO_API, {
      params: params,
    })
  }

  const [promo, setPromo] = useState<PromoList[]>([])
  const [hasMoreItems, setHasMoreItems] = useState(true)
  const countResLen = (res: any) => {
    return res.length
  }

  // #region CHECK FILTER TAB AVAILABILITY
  // #tab that displayed are tab that its data isn't empty
  const [
    filterTabAvailability,
    setFilterTabAvailability,
  ] = useState<FilterTabAvailabilityInterface>({
    all: true,
    mobil_penumpang_baru: false,
    mobil_niaga_baru: false,
    dana_tunai: false,
    modal_usaha: false,
  })

  const updateFilterTabAvailaibility = (key: string, value: boolean) => {
    setFilterTabAvailability((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }
  // #endregion

  // #region CHECK WHICH FILTER TAB THAT IS ALREADY SYNCED (FINISHED CHECKING ITS DATA AVAILABILITY)
  const [syncedFilterTabStatus, setSyncedFilterTabStatus] = useState({
    mobil_penumpang_baru: false,
    mobil_niaga_baru: false,
    dana_tunai: false,
    modal_usaha: false,
  })

  const [isAllFilterSynced, setIsAllFilterSynced] = useState(false)

  useEffect(() => {
    // if all object values in 'syncedFilterTabStatus' above are true,
    // it means it's already finished synchronizing
    if (Object.values(syncedFilterTabStatus).every((item) => item)) {
      setIsAllFilterSynced(true)
    }
  }, [syncedFilterTabStatus])

  const updateSyncedFilterTab = (key: string, value: boolean) => {
    setSyncedFilterTabStatus((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }
  // #endregion

  const handleClick = (pathname: string) => {
    history.push({
      pathname: pathname,
    })
  }

  const isPillsActive = (pathname: string) => {
    const currentPathName = window.location.pathname

    if (currentPathName == pathname) {
      return true
    }

    return false
  }

  // #region SYNC PROMO BY TYPE
  // #for Displaying Filter Tab
  const snycPromoByType = async () => {
    const promoMobilPenumpang = await getPromo({ type: 'mobil_penumpang_baru' })
    if (promoMobilPenumpang) {
      updateSyncedFilterTab('mobil_penumpang_baru', true)

      if (countResLen(promoMobilPenumpang.data['data']) > 0) {
        updateFilterTabAvailaibility('mobil_penumpang_baru', true)
      }
    }

    const promoMobilNiaga = await getPromo({ type: 'mobil_niaga_baru' })
    if (promoMobilNiaga) {
      updateSyncedFilterTab('mobil_niaga_baru', true)

      if (countResLen(promoMobilNiaga.data['data']) > 0) {
        updateFilterTabAvailaibility('mobil_niaga_baru', true)
      }
    }

    const promoDanaTunai = await getPromo({ type: 'dana_tunai' })
    if (promoDanaTunai) {
      updateSyncedFilterTab('dana_tunai', true)

      if (countResLen(promoDanaTunai.data['data']) > 0) {
        updateFilterTabAvailaibility('dana_tunai', true)
      }
    }

    const promoModalUsaha = await getPromo({ type: 'modal_usaha' })
    if (promoModalUsaha) {
      updateSyncedFilterTab('modal_usaha', true)

      if (countResLen(promoModalUsaha.data['data']) > 0) {
        updateFilterTabAvailaibility('modal_usaha', true)
      }
    }
  }
  // #endregion

  // #region PROMO LIST FETCH
  // #to get the Promo Data when page's being accessed
  const promoListFetch = useCallback(async () => {
    setLoading(true)
    const response = await getPromo({ type: 'dana_tunai' })

    if (countResLen(response.data['data']) == 0) {
      setHasMoreItems(false)
      setLoading(false)
      return
    }

    setPromo([...promo, ...response.data['data']])
    setLength(response.data['record_count'])
    setLoading(false)
    setNext(next + 6)
  }, [next])

  useEffect(() => {
    const checkHasMoreItems = next >= length
    setHasMoreItems(!checkHasMoreItems)
  }, [next])

  useEffect(() => {
    snycPromoByType()
    promoListFetch()
    setBreadcrumbList([
      {
        title: 'Home',
        link: '/',
        is_active: false,
      },
      {
        title: 'Promo',
        link: '',
        is_active: true,
      },
    ])
  }, [])
  // #endregion

  useDocumentTitle('Promo Dana Tunai')

  return (
    <div className="promo">
      <Header.Promo
        className="promo-header"
        title={t('Daftar Promo Dana Tunai')}
        description={t('Star Xperience')}></Header.Promo>
      <div className="promo-container">
        <Breadcrumb
          listPages={breadcrumbList}
          separator={mdiChevronRight}></Breadcrumb>

        <div className="promo-list-container">
          {promo.length == 0 && loading ? (
            <>
              <div className="promo-filter">
                <div className="promo-filter__skeleton-container">
                  <Skeleton count={1} height={30} />
                  <Skeleton count={1} height={30} />
                  <Skeleton count={1} height={30} />
                  <Skeleton count={1} height={30} />
                </div>
              </div>

              <CardPromoSkeletonContainer isStacked={true} />
            </>
          ) : (
            <>
              <div className="promo-filter">
                <div className="pills-container">
                  {!isAllFilterSynced ? (
                    <div className="promo-filter__skeleton-container">
                      <Skeleton count={1} height={30} />
                      <Skeleton count={1} height={30} />
                      <Skeleton count={1} height={30} />
                      <Skeleton count={1} height={30} />
                    </div>
                  ) : (
                    <>
                      {filterTabList.map((item, index) => {
                        if (filterTabAvailability[item.code]) {
                          return (
                            <Pills
                              key={index}
                              isActive={isPillsActive(item.pathname)}
                              text={item.title}
                              handleClick={() => handleClick(item.pathname)}
                            />
                          )
                        }
                      })}
                    </>
                  )}
                </div>
              </div>
              {promo.length == 0 ? (
                <p className="no-promo">
                  Tidak ada promo dengan kategori ini. Silakan gunakan filter
                  kategori di atas untuk mencari promo berdasarkan kategori yang
                  tersedia
                </p>
              ) : (
                <InfiniteScroll
                  dataLength={promo.length}
                  next={() => (hasMoreItems ? promoListFetch() : '')}
                  hasMore={hasMoreItems}
                  loader={<CardPromoSkeleton isStacked={true} />}>
                  {promo.map((item, index) => (
                    <CardPromo
                      key={`promo-item-${index}`}
                      data={item}
                      isStacked={true}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </>
          )}
        </div>
      </div>
      <FooterPromo
        type={2}
        id="promo__footer"
        title={t('Apalagi yang Anda tunggu?')}
        description={t(
          'Segera jadi bagian dari StarXperience dan dapatkan banyaknya manfaat yang dapat menunjang kebutuhan hidup Anda.',
        )}></FooterPromo>
    </div>
  )
}

export default PromoDanaTunai
