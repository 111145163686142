//#region IMPORT
import React from 'react'
import { mdiCircleOutline, mdiLoading } from '@mdi/js'
import Icon from '@mdi/react'
import classnames from 'classnames'
import './Loading.scss'
//#endregion

//#region INTERFACE
interface Props {
  className?: string
}
//#endregion

//#region MAIN
export const Loading: React.FC<Props> = ({ className }: Props) => {
  const classes = classnames('loading', className)
  return (
    <div className={classes}>
      <Icon path={mdiLoading} spin className="loading__spiner" />
      <Icon path={mdiCircleOutline} />
    </div>
  )
}
//#endregion
