//#region IMPORT
import React, { useCallback, useEffect, useState } from 'react'
import Axios from 'axios'
import {
  ServiceCategory,
  ServiceProviderList,
  SERVICE_CATEGORY_API,
  SERVICE_PROVIDER_API,
} from '../../constants'
import { ServiceProviderSection } from '../../sections'
import { Header } from '../../../homepage/components'
import './ServiceProviderList.scss'
import t from '../../../../lang'
import IllustrationHeader from '../../../../assets/illustration-provider.svg'
import { PATH } from '../../../wrapper/constants'
import { useDocumentTitle } from '../../../wrapper/utils'
//#endregion

//#region MAIN
export const ServiceProvider: React.FC = () => {
  useDocumentTitle('Service Provider')
  const [service, setService] = useState<ServiceProviderList[]>([])
  const [category, setCategory] = useState<ServiceCategory[]>([])
  const [serviceLoading, setServiceLoading] = useState(false)
  const [categoryLoading, setCategoryLoading] = useState(false)

  const getService = () => {
    return Axios.get(SERVICE_PROVIDER_API, {
      params: {
        limit: 8,
      },
    })
  }

  const getCategory = () => {
    return Axios.get(SERVICE_CATEGORY_API)
  }

  const serviceListFetch = useCallback(async () => {
    setServiceLoading(true)
    const response = await getService()
    setService(response.data['data'])
    setServiceLoading(false)
  }, [getService])

  const categoryListFetch = useCallback(async () => {
    setCategoryLoading(true)
    const response = await getCategory()
    setCategory(response.data['data'])
    setCategoryLoading(false)
  }, [getService])

  useEffect(() => {
    serviceListFetch()
    categoryListFetch()
  }, [])

  return (
    <div className="service-provider">
      <Header.Custom
        className="service-provider__header"
        description={t('Hello,')}
        title={t('Let’s explore the services near you')}>
        <div className="header-image">
          <img src={IllustrationHeader} alt="illustration-header" />
        </div>
      </Header.Custom>
      <ServiceProviderSection
        title={t('Penyedia Layanan')}
        loadMoreLink={PATH.SERVICE_PROVIDER_ALL}
        className="service-provider__section"
        data={service}
        isLoading={serviceLoading}
      />
      <ServiceProviderSection
        title={t('Kategori Layanan')}
        className="service-provider__section"
        category={category}
        isLoading={categoryLoading}
      />
    </div>
  )
}
//#endregion
