import React from 'react'

interface Props {
  branches: any
  displayFull: boolean
}

const FormatBranch = (branches: any, displayFull: boolean) => {
  if (!branches || branches == 'string') {
    return '-'
  } else {
    const splitBranch = branches.split(',')
    let branchName = splitBranch[0]

    if (!displayFull && splitBranch.length > 1) {
      branchName += ` (+${splitBranch.length - 1} area lainnya)`
    } else if (displayFull && splitBranch.length > 1) {
      branchName = branches
    }

    return branchName
  }
}

const BranchList: React.FC<Props> = ({ branches, displayFull }: Props) => {
  return FormatBranch(branches, displayFull)
}

export default BranchList
