//#region IMPORT
import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import Icon from '@mdi/react'
import { mdiChevronUp, mdiChevronDown } from '@mdi/js'

import FrameIphone from '../../../../assets/frame_iphone.png'
import Step1 from '../../../../assets/dana_tunai_step_1.png'
import Step2 from '../../../../assets/dana_tunai_step_2.png'
import Step3 from '../../../../assets/dana_tunai_step_3.png'
import Step4 from '../../../../assets/dana_tunai_step_4.png'
import Step5 from '../../../../assets/dana_tunai_step_5.png'
import './DanaTunaiSteps.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DanaTunaiSteps = () => {
  const [swiperInst, setSwiperInst] = useState<any>(null)
  const [selectedStep, setSelectedStep] = useState(1)
  const [disableSwitch, setDisableSwitch] = useState(false)

  const stepList = [
    {
      id: 1,
      image: Step1,
      desc: 'Download aplikasi StarXperience & aktifkan akun Anda',
    },
    {
      id: 2,
      image: Step2,
      desc: 'Klik menu Dana Tunai untuk lihat plafond Anda',
    },
    {
      id: 3,
      image: Step3,
      desc: 'Klik Tarik Dana & pilih Kontrak Anda',
    },
    {
      id: 4,
      image: Step4,
      desc: 'Lengkapi data sesuai langkah dalam aplikasi',
    },
    {
      id: 5,
      image: Step5,
      desc: 'Tunggu konfirmasi pencairan Anda',
    },
  ]

  const updateSelectedStep = (type: string, custVal?: any) => {
    let currStep = selectedStep
    type == 'up'
      ? (currStep += 1)
      : type == 'down'
      ? (currStep -= 1)
      : (currStep = parseInt(custVal))

    if (currStep > stepList.length) {
      currStep = 1
    } else if (currStep < 1) {
      currStep = stepList.length
    }

    setSelectedStep(currStep)
    type == 'custom' && swiperInst.slideTo(currStep)
  }

  return (
    <div className="steps-dana-tunai">
      <div className="steps-dana-tunai__content">
        <div className="steps-dana-tunai__illustration">
          {/* Will only showed up on Tablet & Mobile */}
          <h2 className="steps-dana-tunai__title-mobile">
            Cara Mengajukan Dana Tunai melalui Aplikasi{' '}
            <span className="accent">StarXperience</span>
          </h2>
          <div className="steps-dana-tunai__frame-container">
            <img className="phone-frame" src={FrameIphone} />
            <Swiper
              onSwiper={(swiper) => {
                setSwiperInst(swiper)
              }}
              onSlideChangeTransitionStart={(swiper) => {
                setDisableSwitch(true)
                swiperInst && updateSelectedStep('custom', swiper.activeIndex)
              }}
              onSlideChangeTransitionEnd={(swiper) => {
                swiperInst && updateSelectedStep('custom', swiper.activeIndex)
                setDisableSwitch(false)
              }}
              navigation={{
                nextEl: '.swiper-custom-button__next',
                prevEl: '.swiper-custom-button__prev',
              }}
              autoplay={{
                delay: 3000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              grabCursor={true}
              loop={true}
              effect={'fade'}
              className="mySwiper">
              {stepList.map((item: any, index: number) => (
                <SwiperSlide key={`swiper-step-${index}`}>
                  <div className="img-slide-container">
                    <img src={item.image} alt={`dana-tunai-step-${index}`} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="steps-dana-tunai__text">
          {/* Will be hidden on Tablet & Mobile */}
          <h2 className="steps-dana-tunai__title">
            Cara Mengajukan Dana Tunai melalui Aplikasi{' '}
            <span className="accent">StarXperience</span>
          </h2>

          <div className="steps-explanation__container">
            {stepList.map((item: any, index: number) => (
              <div
                key={`steps-explanation-${index}`}
                className="steps-explanation__item">
                <input
                  type="radio"
                  id={`step-${item.id}`}
                  name="steps_index"
                  onChange={(e: any) =>
                    updateSelectedStep('custom', e.target.value)
                  }
                  checked={item.id === selectedStep}
                  value={item.id}
                />
                <label htmlFor={`step-${item.id}`}>{item.desc}</label>
              </div>
            ))}
          </div>
          {/* End hidden on Tablet & Mobile */}

          <div className="steps-explanation-mobile">
            <p>{stepList[selectedStep - 1]['desc']}</p>
          </div>

          <div className="swiper-custom-button__container">
            <button
              type="button"
              disabled={disableSwitch}
              onClick={() => updateSelectedStep('down')}
              className="swiper-custom-button__prev">
              <Icon path={mdiChevronUp} size={1.5} />
            </button>
            {swiperInst && (
              <p className="swiper-custom__pagination-mobile">
                {`${selectedStep} / ${stepList.length}`}
              </p>
            )}
            <button
              type="button"
              disabled={disableSwitch}
              onClick={() => updateSelectedStep('up')}
              className="swiper-custom-button__next">
              <Icon path={mdiChevronDown} size={1.5} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DanaTunaiSteps
