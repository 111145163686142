//#region IMPORT
import React, { useCallback, useEffect, useState } from 'react'
import Axios from 'axios'

import FileService from '../../../../services/fileService/http'
import './LivenessDetection.scss'
import { Button } from '../../../../components'

import { LIVENESS_API } from '../../constant'
import { base64ToBlob, useDocumentTitle } from '../../../wrapper/utils'
import { useHistory, useLocation } from 'react-router-dom'
import { PATH } from '../../../wrapper/constants'
import Icon from '@mdi/react'
import { mdiLoading } from '@mdi/js'
import Swal from 'sweetalert2'
import DanatunaiService from '../../../../services/danaTunai/http'
//#endregion

const LivenessDetection: React.FC = () => {
  const pathname: any = useLocation()
  const history = useHistory()

  const [pageTitle, setPageTitle] = useState('Verifikasi Wajah Pemilik BPKB')
  const [loading, setLoading] = useState(false)
  const [isFetchError, setIsFetchError] = useState(false)
  const [displayReupload, setDisplayReupload] = useState(false)

  const [mobileParam, setMobileParam] = useState('')
  const [livenessUrl, setLivenessUrl] = useState('')
  const [uploadedFileURL, setUploadedFileURL] = useState('')
  const [reuploadType, setReuploadType] = useState('')
  const [detectionResult, setDetectionResult] = useState({
    face_1: '',
    face_2: '',
    fc_token: '',
  })

  useDocumentTitle('Verifikasi Wajah')
  const urlParams: any = new URLSearchParams(pathname.search)
  const redirectParam = urlParams.get('redirect_to')
  const customerId = urlParams.get('customer_id')
  const activationId = urlParams.get('activation_id')
  const documentCode = urlParams.get('document_code')

  const getLivenessURL = () => {
    return Axios.get(LIVENESS_API, {})
  }

  const displayTimeout = () => {
    const wrapperContent: any = document.getElementsByClassName(
      'wrapper-content',
    )[0]
    wrapperContent && wrapperContent.classList.add('full-height')

    setLoading(false)
    setIsFetchError(true)
    const currentUrl = window.location.href
    if (!currentUrl.includes('timeout')) {
      window.history.pushState(
        'Fetch Privy URL Timeout',
        'StarX - Liveness Detection',
        `${currentUrl}&timeout=true`,
      )
    }
  }

  const livenessURLFetch = useCallback(async () => {
    setLoading(true)
    const response = await getLivenessURL()

    if (response.data['code'] == 200) {
      const startLoad: any = new Date()
      setLivenessUrl(response.data['data']['user_landing_url'])

      // Check if loading is complete
      const iframe: any = document.getElementById('livenessIframe')
      iframe.addEventListener('load', () => {
        setLoading(false)

        const endLoad: any = new Date()
        let timeDiff = endLoad - startLoad
        timeDiff /= 1000

        // if time elapsed to load the iframe
        // then there must be an error
        const seconds = Math.round(timeDiff)
        if (seconds > 10) {
          displayTimeout()
          return false
        }
      })

      return
    } else {
      displayTimeout()
    }
    setLoading(false)
  }, [])

  const isObjectEmpty = (objName: any) => {
    const testResult = Object.values(objName).every(
      (key) => key === null || key === '' || typeof key === 'undefined',
    )
    return testResult
  }

  const base64ToFile = (base64file: string) => {
    const convertedFile = base64ToBlob(base64file)
    const generatedImage = new File(
      [convertedFile],
      `customer_${activationId}_${documentCode}.jpg`,
      {
        type: 'image/jpeg',
      },
    )

    return generatedImage
  }

  //#region FUNCTION UPLOAD FILE
  const uploadFile = async (file: any) => {
    const res = await FileService.create({
      app_name: 'starx',
      profile_name: '3mb',
      tags: `customer_${activationId}_${documentCode}`,
      files: file,
    }).catch((err) => err.response)

    if (!res.data.status) {
      setDisplayReupload(true)
      setReuploadType('fileService')
      Swal.fire({
        title: 'Terjadi Kesalahan!',
        text: `${res.data.message}. Silakan upload kembali foto menggunakan tombol Upload Ulang di bawah`,
        icon: 'error',
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    } else {
      setDisplayReupload(false)
      setUploadedFileURL(res.data.data.file_url)
    }
    return res.data.status
  }
  //#endregion

  //#region FUNCTION UPDATE ACTIVATION DOC
  const updateActivationDoc = async (fileUrl: any) => {
    const res = await DanatunaiService.createAgreementActivationDoc({
      customer_id: customerId,
      activation_id: activationId,
      data: {
        documents: [
          {
            file_url: fileUrl,
            document_group_code: documentCode,
            status: 'requested',
          },
        ],
      },
    }).catch((err) => err.response)
    if (!res.data.status) {
      setDisplayReupload(true)
      setReuploadType('activationDoc')
      Swal.fire({
        title: 'Terjadi Kesalahan!',
        text: `${res.data.message}. Silakan upload kembali foto menggunakan tombol Upload Ulang di bawah`,
        icon: 'error',
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    } else {
      setDisplayReupload(false)
    }
    return res.data.status
  }
  //#endregion

  const handleReupload = () => {
    if (reuploadType == 'fileService') {
      uploadFile(base64ToFile(detectionResult?.face_2))
    } else if (reuploadType == 'activationDoc') {
      updateActivationDoc(uploadedFileURL)
    }
  }

  useEffect(() => {
    livenessURLFetch()
    setMobileParam(urlParams.get('mobile'))

    if (redirectParam && redirectParam == 'BPKB_OWNER_SPOUSE') {
      setPageTitle('Verifikasi Wajah Pasangan Pemilik BPKB')
    }
  }, [])

  //#region AUTOMATICALLY UPDATE THE ACTIVATION DATA
  // WHEN THE UPLOAD TO FILESERVICE IS SUCCESS
  // (WHEN UPLOADED FILE URL IS NOT EMPTY)
  useEffect(() => {
    if (uploadedFileURL != '') {
      const updateActivationTrigger = async () => {
        const updateActivationResult = await updateActivationDoc(
          uploadedFileURL,
        )

        if (updateActivationResult) {
          const currentUrl = window.location.href
          if (!currentUrl.includes('checking=done')) {
            window.history.pushState(
              'Update checking done',
              'StarX - Liveness Detection',
              `${currentUrl}&checking=done`,
            )
          }

          window.scrollTo(0, document.body.scrollHeight)
        }
      }

      updateActivationTrigger()
    }
  }, [uploadedFileURL])
  //# endregion

  useEffect(() => {
    window.addEventListener(
      'message',
      function (e: any) {
        const key = 'message' in e ? 'message' : 'data'
        const data = e[key]
        // only trust event from privypass_liveness source
        if (data.data?.result && data.source === 'privypass_liveness') {
          const resultData = data.data
          setDetectionResult({
            face_1: resultData.face_1,
            face_2: resultData.face_2,
            fc_token: resultData.fc_token,
          })
          if (urlParams.get('mobile')) {
            uploadFile(base64ToFile(resultData.face_2))
          }
        }
      },
      false,
    )
  }, [pageTitle])

  return (
    <div className="liveness-detection">
      {loading && (
        <div className="liveness-detection__loading">
          <div className="liveness-detection__loading--inner">
            <div>
              <Icon path={mdiLoading} spin size={2} />
              <p className="mb-0">Mendapatkan URL Liveness Detection...</p>
            </div>
          </div>
        </div>
      )}

      {!mobileParam && <h3 className="page-title">{pageTitle}</h3>}

      {!isFetchError ? (
        <>
          <iframe
            id="livenessIframe"
            src={livenessUrl}
            scrolling="no"
            allow="camera"
            title="Privy Liveness Detection"></iframe>

          {!isObjectEmpty(detectionResult) && !mobileParam ? (
            <div className="additional-action-container">
              <Button
                elId="redirectButton"
                text="Tutup Halaman"
                className="mx-0 d-none"
                onClick={() => {
                  const redirectParam = urlParams.get('redirect_to')
                  let searchParam = `?redirect_to=${redirectParam}&token=${urlParams.get(
                    'token',
                  )}`
                  if (mobileParam) {
                    searchParam = `?redirect_to=${redirectParam}&token=${urlParams.get(
                      'token',
                    )}&mobile=${mobileParam}`
                  }

                  if (redirectParam == 'BPKB_OWNER') {
                    localStorage.setItem(
                      'BPKB_OWNER_LIVENESS_DATA',
                      JSON.stringify(detectionResult),
                    )

                    history.push({
                      pathname: PATH.BPKB_OWNER,
                      search: searchParam,
                    })
                  } else {
                    localStorage.setItem(
                      'BPKB_OWNER_SPOUSE_LIVENESS_DATA',
                      JSON.stringify(detectionResult),
                    )

                    history.push({
                      pathname: PATH.BPKB_OWNER_SPOUSE,
                      search: searchParam,
                      state: {
                        form_token: urlParams.get('token'),
                      },
                    })
                  }
                }}></Button>
            </div>
          ) : (
            ''
          )}

          {displayReupload && (
            <div className="additional-action-container">
              <Button
                elId="reuploadButton"
                text="Upload ulang"
                data-action="reupload-privy"
                onClick={handleReupload}></Button>
            </div>
          )}
        </>
      ) : (
        <h4 className="text-primary">
          Mohon maaf terjadi kesalahan pada sistem kami. Silahkan dicoba
          kembali.
        </h4>
      )}
    </div>
  )
}

export default LivenessDetection
