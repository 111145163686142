//#region IMPORT
import React from 'react'
import {
  DanaTunaiDesc,
  DanaTunaiBenefit,
  DanaTunaiSteps,
  DanaTunaiInfo,
} from '../../components'
import { Header } from '../../../homepage/components'
import FooterPromo from '../../../promo/components/FooterPromo'

import HeaderIllustration from '../../../../assets/danatunai_header_illustration_v2.png'
import HeaderBackgroundDesktop from '../../../../assets/danatunai_header_desktop.png'
import HeaderBackgroundMobile from '../../../../assets/danatunai_header_mobile.png'
import DanaTunaiInfoContent from '../../entity/starx_danatunai_info.json'
import {
  useDocumentTitle,
  externalLink,
  useWindowDimensions,
} from '../../../wrapper/utils'
import t from '../../../../lang'
import './DanaTunai.scss'

const DanaTunai: React.FC = () => {
  useDocumentTitle('Dana Tunai')
  const { width } = useWindowDimensions()
  const headerCTAHandler = (destination: string) => {
    if (width > 992) {
      const destinationPos = document.getElementById(destination)?.offsetTop
      window.scroll({
        top: destinationPos ? destinationPos - 64 : destinationPos,
        left: 0,
        behavior: 'smooth',
      })
    } else {
      externalLink(
        'https://starxperience.id/deep-link/dana-tunai',
        'deep-link-redirect',
      )
    }
  }

  return (
    <div className="dana-tunai">
      <Header.Custom
        className="dana-tunai-header"
        title={t('<b>Dana Tunai</b> untuk Segala Kebutuhan Anda!')}
        action={[
          {
            className: 'dana-tunai-header__ajukan-button',
            clickAction: () => {
              headerCTAHandler('footer-dana-tunai')
            },
            btnText: t('Ajukan dalam Aplikasi'),
          },
        ]}
        background={{
          desktop: HeaderBackgroundDesktop,
          mobile: HeaderBackgroundMobile,
        }}>
        <div className="dana-tunai-header-image">
          <img src={HeaderIllustration} alt="" />
        </div>
      </Header.Custom>
      <DanaTunaiDesc />
      <DanaTunaiBenefit />
      <DanaTunaiSteps />
      <DanaTunaiInfo removedEl="h2" datas={DanaTunaiInfoContent} />
      <FooterPromo
        type={2}
        id="footer-dana-tunai"
        title={t('Apalagi yang Anda tunggu?')}
        description={t(
          'Segera jadi bagian dari StarXperience dan dapatkan banyaknya manfaat yang dapat menunjang kebutuhan hidup Anda.',
        )}
      />
    </div>
  )
}

export default DanaTunai
