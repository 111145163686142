//#region IMPORT PACKAGE
import classNames from 'classnames'
import React, { useState } from 'react'
import { Logo } from '../../../../assets/icon'
import t from '../../../../lang'
import { PATH, META_TAG_LIST } from '../../constants'
import { useHistory, useLocation } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiMenu as IconMenu } from '@mdi/js'
//#endregion

//#region IMPORT COMPONENT
import NavbarItem from '../NavbarItem'
import './Navbar.scss'
import { Button } from '../../../../components'
import { externalLink } from '../../utils'
import { Env } from '../../../../config/Config'
//#endregion

type Props = {
  isCollapse?: boolean
  className?: string
}

const Navbar: React.FC<Props> = (props: Props) => {
  const [expanded, setExpanded] = useState(false)

  //#region CLASSESS
  const classes = classNames(
    'navbar',
    expanded && 'navbar--collapse',
    props.className,
  )
  //#endregion

  const history = useHistory()
  const pathname = useLocation().pathname
  const onMenuToggle = () => {
    setExpanded(!expanded)
  }
  const onMenuClick = (path: string) => {
    META_TAG_LIST.map((item) => {
      const metaEl = document.head.querySelector(
        `[property="${item.propertyName}"]`,
      )
      metaEl?.setAttribute('content', '')
    })

    history.push(path)
    setExpanded(false)

    // auto close the expanded submenu
    Array.from(
      document.querySelectorAll('.navbar-submenu-mobile'),
    ).forEach((el) => el.classList.remove('expanded'))
  }
  const layananSubmenu = [
    {
      title: 'Dana Tunai',
      path: PATH.DANA_TUNAI,
      click: (): void => onMenuClick(PATH.DANA_TUNAI),
    },
    {
      title: 'Lainnya',
      path: PATH.PRODUCT,
      click: (): void => onMenuClick(PATH.PRODUCT),
    },
  ]
  return (
    <div className={classes}>
      <div className="navbar-logo" onClick={(): void => onMenuClick(PATH.HOME)}>
        <Logo />
      </div>
      <div className="navbar-toggle" onClick={onMenuToggle}>
        <Icon path={IconMenu} />
      </div>
      <div className="navbar-menu">
        <NavbarItem
          title={t('Home')}
          onClick={(): void => onMenuClick(PATH.HOME)}
          isActive={pathname === PATH.HOME}
        />
        <NavbarItem
          title={t('Promo')}
          onClick={(): void => onMenuClick(PATH.PROMO)}
          isActive={pathname.includes(PATH.PROMO)}
        />
        <NavbarItem
          title={t('Join as a partner')}
          onClick={(): void => onMenuClick(PATH.PARTNER)}
          isActive={pathname === PATH.PARTNER}
        />
        <NavbarItem
          title={t('Services')}
          onClick={(): void => {}}
          isActive={false}
          submenu={layananSubmenu}
        />
        <Button
          compact
          variant="outline"
          elId="ButtonLogin"
          text={t('Login')}
          className="button--login"
          onClick={() => {
            externalLink(Env.base_url + PATH.LOGIN_SP, 'LOGIN')
          }}
        />
      </div>
    </div>
  )
}

export default Navbar
