//#region IMPORT
import React from 'react'
import { mdiChevronUp } from '@mdi/js'
import Icon from '@mdi/react'
import './FAB.scss'
//#endregion

//#region INTERFACE
interface Props {
  onClick?(): void
}
//#endregion

//#region MAIN
export const FAB: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <div
      data-aos="slide-left"
      data-aos-duration={500}
      className="fab"
      onClick={onClick}>
      <Icon path={mdiChevronUp} />
    </div>
  )
}
//#endregion
