import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import ReactGA from 'react-ga4'
import { Env } from '../../../config/Config'
import TagManager from 'react-gtm-module'

// eslint-disable-next-line
export const externalLink = (link: string, label?: string, target?: string) => {
  window.open(link, target ?? '_blank')
  ReactGA.initialize(Env.tracking_id)
  ReactGA.event({
    category: 'STARX_EXTERNAL_LINK',
    label: label,
    action: link,
  })
}

// eslint-disable-next-line
export const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

// eslint-disable-next-line
export const PageTrackingGA = () => {
  useEffect(() => {
    ReactGA.initialize(Env.tracking_id)
    ReactGA.send('pageview')
  })

  return null
}

// eslint-disable-next-line
export const PageTrackGTM = () => {
  const tagManagerArgs = {
    gtmId: Env.gtm_id,
    dataLayerName: 'PageDataLayer',
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  })

  return null
}

// eslint-disable-next-line
export const useDocumentTitle = (title: string, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title)

  useEffect(() => {
    document.title = 'StarX - ' + title
  }, [title])

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current
      }
    },
    [],
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const base64ToBlob = (dataURI: string) => {
  const imageBytes = atob(dataURI.split(',')[1])

  // Convert the binary data to Uint8Array
  const byteArray = new Uint8Array(imageBytes.length)
  for (let i = 0; i < imageBytes.length; i++) {
    byteArray[i] = imageBytes.charCodeAt(i)
  }

  // eslint-disable-next-line no-undef
  const imageBlob = new Blob([byteArray as BlobPart], { type: 'image/jpeg' })
  return imageBlob
}

// eslint-disable-next-line
export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

// eslint-disable-next-line
export function getScreenOrientation(orientationType: string) {
  const isMatch = window.matchMedia(orientationType).matches
  return isMatch
}

// eslint-disable-next-line
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
