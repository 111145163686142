//#region IMPORT
import React, { ReactElement, useEffect, useState } from 'react'
import classnames from 'classnames'
import './Header.scss'
import { useWindowDimensions } from '../../../wrapper/utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination, SwiperOptions } from 'swiper'
import 'swiper/components/pagination/pagination.scss'
import { Slider } from '../../constants'
import parse from 'html-react-parser'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
//#endregion

//#region MAIN COMPONENT
//#region INTERFACE
type Props = {
  isLoading?: boolean
  isMobile?: boolean
  className?: string
  title?: string
  description?: string
  children?: ReactElement | string
  imageUrl?: {
    desktop: string
    mobile: string
  }
  action?: {
    className: string
    clickAction(): void
    btnText: string
  }[]
  background?: {
    desktop: string
    mobile: string
  }
}
//#endregion
export const Banner: React.FC<Props> = ({ imageUrl }: Props) => {
  const { width } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    width < 768 ? setIsMobile(true) : setIsMobile(false)
  })

  return (
    <div className="header-banner">
      <img
        src={isMobile ? imageUrl?.mobile : imageUrl?.desktop}
        alt="banner-home"
      />
    </div>
  )
}

SwiperCore.use([Autoplay, Pagination])
export interface CarouselProps extends Props {
  sliders: Slider[]
  loading?: boolean
  config?: SwiperOptions
}

export const Carousel: React.FC<CarouselProps> = ({
  sliders,
  loading,
  config,
}: CarouselProps) => {
  const { width } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    width < 768 ? setIsMobile(true) : setIsMobile(false)
  })

  return (
    <div className="header-carousel">
      <Swiper
        navigation={config?.navigation ?? true}
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        loop={config?.loop ?? true}
        speed={750}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        {...config}>
        {loading && <div>Loading...</div>}
        {sliders.map((slide, i) => (
          <SwiperSlide key={`slide-item-${i}`}>
            {slide.hyperlink ? (
              <a href={slide.hyperlink} target="_blank" rel="noreferrer">
                <img
                  src={
                    isMobile
                      ? slide.image_url?.mobile
                      : slide.image_url?.desktop
                  }
                  alt="banner-home"
                />
              </a>
            ) : (
              <img
                src={
                  isMobile ? slide.image_url?.mobile : slide.image_url?.desktop
                }
                alt="banner-home"
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export const Custom: React.FC<Props> = ({
  className,
  title,
  description,
  children,
  action,
  background,
}: Props) => {
  //#region CLASSES
  const classes = classnames('header-custom', className)
  //#endregion

  return (
    <div className={classes}>
      {background && (
        <>
          <img
            className="header-custom__bg-desktop"
            src={background?.desktop}
            alt=""
          />
          <img
            className="header-custom__bg-mobile"
            src={background?.mobile}
            alt=""
          />
        </>
      )}
      <div className="header-custom__content">
        <div className="header-custom__text">
          <div className="header-custom__title">
            {parse(title ? title : '')}
          </div>
          <div className="header-custom__description">{description}</div>
          {action &&
            action.map((item: any, index: number) => (
              <button
                key={`header-btn-action=${index}`}
                className={item.className}
                onClick={item.clickAction ? item.clickAction : {}}>
                {item.btnText}
              </button>
            ))}
        </div>
        {children}
      </div>
    </div>
  )
}

export const Promo: React.FC<Props> = ({
  isLoading,
  isMobile,
  className,
  title,
  description,
}: Props) => {
  //#region CLASSES
  const classes = classnames('header-promo', className)
  //#endregion

  return (
    <div className={classes}>
      <div className="header-promo__content">
        <div className="header-promo__text">
          <h1 className="header-promo__title">
            {isLoading ? (
              <Skeleton
                baseColor="#172869"
                highlightColor="#3b4f9b"
                width={isMobile ? 300 : 500}
                height={30}
              />
            ) : (
              title
            )}
          </h1>
          {isLoading && !description ? (
            <Skeleton
              baseColor="#172869"
              highlightColor="#3b4f9b"
              width={isMobile ? 300 : 800}
            />
          ) : (
            <></>
          )}
          {description && (
            <div className="header-promo__description">{description}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export const SingleHeader: React.FC<Props> = ({ className, title }: Props) => {
  //#region CLASSES
  const classes = classnames('header-single', className)
  //#endregion

  return (
    <div className={classes}>
      <div className="header-single__content">
        <div className="header-single__text">
          <h3 className="header-single__title">{title}</h3>
        </div>
      </div>
    </div>
  )
}

//#endregion
