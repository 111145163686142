//#region IMPORT
import React, { ButtonHTMLAttributes, forwardRef, ReactElement } from 'react'
import classnames from 'classnames'
import './Button.scss'
import Icon from '@mdi/react'
import { mdiLoading } from '@mdi/js'
//#endregion

//#region MAIN COMPONENT
//#region INTERFACE
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  elId: string
  text?: string
  iconOnly?: ReactElement
  iconLeft?: ReactElement
  iconRight?: ReactElement
  isRounded?: boolean
  disabled?: boolean
  variant?: 'outline' | 'contained'
  compact?: boolean
  onClick?(): void
  fullWidth?: boolean
  loading?: boolean
  color?: 'secondary' | 'primary' | 'tertiary'
  image?: string
}
//#endregion

// eslint-disable-next-line react/display-name
const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      isRounded,
      variant,
      compact,
      className,
      elId,
      onClick,
      disabled,
      iconOnly,
      iconLeft,
      text,
      iconRight,
      fullWidth,
      loading,
      color,
      image,
      ...others
    }: Props,
    ref,
  ) => {
    //#region CLASSES
    let btnColor = 'button--primary'
    if (color === 'secondary') {
      btnColor = 'button--secondary'
    } else if (color === 'tertiary') {
      btnColor = 'button--tertiary'
    }

    const classes = classnames(
      'button',
      isRounded && 'button--rounded',
      variant === 'outline' && 'button--outlined',
      compact && 'button--compact',
      { 'button--fullwidth': fullWidth },
      btnColor,
      className,
    )
    //#endregion

    return (
      <button
        ref={ref}
        id={elId}
        className={classes}
        onClick={onClick}
        disabled={disabled}
        {...others}>
        {iconOnly && <div className="button__icon-only">{iconOnly}</div>}
        {iconLeft && <div className="button__icon-left">{iconLeft}</div>}
        {text && (
          <div className="button__text">
            {loading ? <Icon path={mdiLoading} spin size={1} /> : text}
          </div>
        )}
        {image && <img src={image} />}
        {iconRight && <div className="button__icon-right">{iconRight}</div>}
      </button>
    )
  },
)

export default Button

//#endregion
