export const serviceCodeToIcon = (code: string): string => {
  return code === 'BLC'
    ? '../images/ic_category/balancing.svg'
    : code === 'PCW'
    ? '../images/ic_category/carwash.svg'
    : code === 'CLN'
    ? '../images/ic_category/cleaning.svg'
    : code === 'CTNG'
    ? '../images/ic_category/coating.svg'
    : code === 'DTLNG'
    ? '../images/ic_category/detailing.svg'
    : code === 'JPSTNK'
    ? '../images/ic_category/stnk.svg'
    : code === 'PLS'
    ? '../images/ic_category/poles.svg'
    : code === 'RPAK'
    ? '../images/ic_category/rust.svg'
    : code === 'SBK'
    ? '../images/ic_category/service_berkala.svg'
    : code === 'SPR'
    ? '../images/ic_category/spooring.svg'
    : '../images/ic_category/service_mobil.svg'
}
