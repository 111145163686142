//#region IMPORT
import React from 'react'
import classnames from 'classnames'
import './HeaderGrid.scss'

import GridImage1 from '../../../../assets/grid_image_1.jpg'
import GridImage2 from '../../../../assets/grid_image_2.jpg'
import GridImage3 from '../../../../assets/grid_image_3.jpg'
import GridImage4 from '../../../../assets/grid_image_4.jpg'
import GridImage5 from '../../../../assets/grid_image_5.jpg'
import GridImage6 from '../../../../assets/grid_image_6.jpg'
import GridImage7 from '../../../../assets/grid_image_7.jpg'
import GridImage8 from '../../../../assets/grid_image_8.jpg'
import GridImage9 from '../../../../assets/grid_image_9.jpg'
import GridImage10 from '../../../../assets/grid_image_10.jpg'
import GridImage11 from '../../../../assets/grid_image_11.jpg'
//#endregion

//#region MAIN COMPONENT
//#region INTERFACE
type Props = {
  className?: string
}
//#endregion

const HeaderGrid: React.FC<Props> = ({ className }: Props) => {
  //#region CLASSES
  const classes = classnames('header-grid', { className })
  //#endregion

  //#region IMAGE LIST
  const imageList = [
    GridImage1,
    GridImage2,
    GridImage3,
    GridImage4,
    GridImage5,
    GridImage6,
    GridImage7,
    GridImage8,
    GridImage9,
    GridImage10,
    GridImage11,
  ]
  //#endregion

  //#region GRID ITEM
  const GridItem = (imageUrl?: string, index?: number) => {
    return (
      <div
        key={`item-${index}`}
        className={`header-grid-item index--${index ? index.toString() : 0}`}>
        {imageUrl && <img src={imageUrl} alt="" />}
      </div>
    )
  }
  //#endregion

  return (
    <div className={classes}>
      {imageList.map((item, index) => GridItem(item, index))}
    </div>
  )
}

export default HeaderGrid

//#endregion
