//#region IMPORT
import React from 'react'
import { ServiceCategoryItem, ServiceProviderItem } from '../../components'
import { ServiceCategory, ServiceProviderList } from '../../constants'
import classnames from 'classnames'
import './ServiceProviderSection.scss'
import { useHistory } from 'react-router-dom'
import { Loading } from '../../../../components'
import { PATH } from '../../../wrapper/constants'
//#endregion

interface Props {
  className?: string
  title?: string
  loadMoreLink?: string
  data?: ServiceProviderList[]
  category?: ServiceCategory[]
  isLoading?: boolean
}

//#region MAIN
export const ServiceProviderSection: React.FC<Props> = ({
  className,
  title,
  loadMoreLink,
  data,
  category,
  isLoading,
}: Props) => {
  const classes = classnames('service-provider-section', className)
  const history = useHistory()

  const onCategoryClick = (id: number) => {
    history.push({
      pathname: PATH.SERVICE_PROVIDER_ALL,
      search: `?category=${id}`,
    })
  }

  return (
    <div className={classes}>
      <div className="service-provider-section__title">{title}</div>
      {loadMoreLink && (
        <div className="service-provider-section__action">
          <p onClick={() => history.push(loadMoreLink)}>Lihat Semua</p>
        </div>
      )}
      {isLoading ? (
        <Loading className="service-provider-section__loading" />
      ) : (
        <div className="service-provider-section__content">
          {data &&
            data.map((item, index) => (
              <ServiceProviderItem
                key={`service-provider-item-${index}`}
                images={item.service_photos}
                name={item.name}
                categories={item.service_group_name}
                rating={item.rating}
                location={item.location}
              />
            ))}

          {category &&
            category.map((item, index) => (
              <ServiceCategoryItem
                key={`service-provider-item-${index}`}
                name={item.name}
                serviceGroup={item.service_group}
                code={item.code}
                onClick={() => onCategoryClick(item.id)}
              />
            ))}
        </div>
      )}
    </div>
  )
}
//#endregion
