//#region IMPORT
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import './ProductService.scss'
import { ProductServiceItem } from '..'

import { ProductList } from '../../constants'
import productListJSON from '../../entity/product.json'
//#endregion

//#region MAIN COMPONENT
//#region INTERFACE
type Props = {
  className?: string
  id?: string
  title?: string
}
//#endregion

const ProductService: React.FC<Props> = ({ className, id, title }: Props) => {
  //#region CLASSES
  const classes = classnames('product-service', className)
  //#endregion

  const [productList, setProductList] = useState<ProductList[]>([])

  useEffect(() => {
    setProductList(productListJSON)
  }, [])

  return (
    <div id={id} className={classes}>
      <div className="product-service__title">{title}</div>
      <div className="product-service__content">
        {productList.map((item, index) => (
          <ProductServiceItem
            key={`service-${index}`}
            title={item.title}
            imageUrl1={item.imageUrl1}
            imageUrl2={item.imageUrl2}
            description={item.description}
            serviceItem={item.service}
          />
        ))}
      </div>
    </div>
  )
}

export default ProductService

//#endregion
