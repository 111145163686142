/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'
import { Env } from '../../config/Config'
import { BannerParams, PromoParams } from './types'

const http = axios.create({
  baseURL: Env.api_services.blog,
  headers: {
    'Content-type': 'application/json',
  },
})
const getBanner = (params: BannerParams) => {
  return http.get('/banner', {
    params: {
      status: params.status,
      current_date: params.current_date,
    },
  })
}

const getPromo = (params: PromoParams) => {
  return http.get('/promo', {
    params: {
      status: params.status,
      current_date: params.current_date,
    },
  })
}

const BlogService = {
  getBanner,
  getPromo,
}

export default BlogService
