//#region IMPORT
import React from 'react'
import SweetAlert, { SweetAlert2Props } from 'react-sweetalert2'
import './Modal.scss'
//#endregion

//#region INTERFACE
//#endregion

//#region MAIN
export const Image: React.FC<SweetAlert2Props> = ({
  imageUrl,
  ...others
}: SweetAlert2Props) => {
  return (
    <SweetAlert
      allowEnterKey={false}
      allowOutsideClick={false}
      allowEscapeKey={false}
      imageUrl={imageUrl}
      {...others}
    />
  )
}
//#endregion
