//#region IMPORT
import React from 'react'
import { ServiceGroup } from '../../constants'
import './ServiceCategoryItem.scss'
import { serviceCodeToIcon } from '../../utils'
import { Loading } from '../../../../components'
//#endregion

//#region INTERFACE
interface Props {
  serviceGroup: ServiceGroup
  code: string
  name: string
  onClick?(): void
}
//#endregion

//#region MAIN
export const ServiceCategoryItem: React.FC<Props> = ({
  serviceGroup,
  code,
  name,
  onClick,
}: Props) => {
  return (
    <div
      data-aos="fade-in"
      data-aos-duration={500}
      className="service-category-item"
      onClick={onClick}>
      <div className="service-category-item__image">
        <img
          src={serviceCodeToIcon(code)}
          alt="empty-image"
          onLoad={() => <Loading />}
        />
      </div>
      <div className="service-category-item__title">{name}</div>
      <div className="service-category-item__description">
        {serviceGroup.service_group}
      </div>
    </div>
  )
}
//#endregion
