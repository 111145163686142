//#region IMPORT
import React from 'react'
import './Pills.scss'
//#endregion

//#region INTERFACE
interface Props {
  pillsData?: { [key: string]: any }[]
  isActive?: boolean
  text?: string
  handleClick?: any
}
//#endregion

//#region MAIN COMPONENT
export const Pills: React.FC<Props> = ({
  isActive,
  text,
  handleClick,
}: Props) => {
  return (
    <div
      className={`pills-item ${isActive ? 'active' : ''}`}
      onClick={handleClick}>
      <span>{text}</span>
    </div>
  )
}
//#endregion
