//#region IMPORT
import React from 'react'
import DanaTunaiBenefitIllustration from '../../../../assets/girl.png'
import LowInterestIllustration from '../../../../assets/3d-low-interest.png'
import FreeIllustration from '../../../../assets/3d-free.png'
import parse from 'html-react-parser'
import './DanaTunaiBenefit.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DanaTunaiBenefit = () => {
  const benefitList = [
    {
      image: LowInterestIllustration,
      caption: 'Bunga <br> Rendah',
    },
    {
      image: FreeIllustration,
      caption: 'Tanpa Biaya Provisi',
    },
  ]
  return (
    <div className="benefit-dana-tunai">
      <img
        src={DanaTunaiBenefitIllustration}
        className="benefit-illustration-mobile"
        alt="Benefit Illustration"
      />

      <div className="benefit-dana-tunai__content">
        <div className="benefit-dana-tunai__illustration">
          <img src={DanaTunaiBenefitIllustration} alt="Benefit Illustration" />
        </div>
        <div className="benefit-dana-tunai__text">
          <h2 className="benefit-dana-tunai__title">
            Keuntungan <span className="accent">Dana Tunai</span>
          </h2>
          <p className="benefit-dana-tunai__benefit">
            Beberapa keuntungan dengan menggunakan fasilitas Dana Tunai.
          </p>

          <div className="benefit-dana-tunai__card-list-container">
            {benefitList.map((item: any, index: number) => (
              <div
                key={`benefit-${index}`}
                className="benefit-dana-tunai__card-item-container">
                <div className="benefit-dana-tunai__card-item">
                  <img src={item.image} alt={`benefit-icon-${index}`} />
                </div>
                <p className="benefit-dana-tunai__card-caption">
                  {parse(item.caption)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DanaTunaiBenefit
