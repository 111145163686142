//#region IMPORT
import { Field, Form, Formik, FormikHelpers } from 'formik'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Button, Card, Input } from '../../../../components'
import * as Yup from 'yup'
import './BPKBOwnerRevise.scss'
import { useDocumentTitle } from '../../../wrapper/utils'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
  bpkbOwnerSelector,
  getBpkbOwnerDetail,
} from '../../../../redux/bpkbOwner'
import { BPKBOwnerProps, Document } from '../../constants/types'
import { PATH } from '../../../wrapper/constants'
import { useHistory } from 'react-router-dom'
import FileService from '../../../../services/fileService/http'
import Swal from 'sweetalert2'
import DanatunaiService from '../../../../services/danaTunai/http'
//#endregion

//#region INTERFACE
type Values = {
  FotoKTPBPKBOwner?: string
  FotoSelfieBPKBOwner?: string
  FotoKTPBPKBOwnerSpouse?: string
  FotoSelfieBPKBOwnerSpouse?: string
  files?: {
    FotoKTPBPKBOwner: any
    FotoSelfieBPKBOwner: any
    FotoKTPBPKBOwnerSpouse: any
    FotoSelfieBPKBOwnerSpouse: any
  }
  documents: any[]
}
type Allowed =
  | 'FotoKTPBPKBOwner'
  | 'FotoSelfieBPKBOwner'
  | 'FotoKTPBPKBOwnerSpouse'
  | 'FotoSelfieBPKBOwnerSpouse'
//#endregion

//#region MAIN
const BPKBOwnerRevise: React.FC = () => {
  //#region GENERAL
  useDocumentTitle('Revisi Data Pemilik BPKB')
  const dispatch = useAppDispatch()
  const { bpkbOwnerDetail, loading } = useAppSelector(bpkbOwnerSelector)
  const detail: BPKBOwnerProps = bpkbOwnerDetail.data
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const token = urlParams.get('token')
  const history = useHistory()
  const [submitted, setSubmitted] = useState<string>()
  const allowed = [
    'FotoKTPBPKBOwner',
    'FotoSelfieBPKBOwner',
    'FotoKTPBPKBOwnerSpouse',
    'FotoSelfieBPKBOwnerSpouse',
  ]
  const label = {
    FotoKTPBPKBOwner: 'KTP',
    FotoSelfieBPKBOwner: 'Selfie&KTP',
    FotoKTPBPKBOwnerSpouse: 'KTP Pasangan',
    FotoSelfieBPKBOwnerSpouse: 'Selfie&KTP Pasangan',
  }
  const [documents, setDocuments] = useState<any[]>([])
  const [error, setError] = useState<string[]>([])
  //#endregion

  //#region VALIDATION
  const validation = {
    ...documents.map((d) => d.document_group.code),
  }

  const validationSchema = Yup.object().shape({
    ...(Object.values(validation).includes('FotoKTPBPKBOwner') && {
      FotoKTPBPKBOwner: Yup.string().nullable().required(''),
    }),
    ...(Object.values(validation).includes('FotoKTPBPKBOwnerSpouse') && {
      FotoKTPBPKBOwnerSpouse: Yup.string().nullable().required(''),
    }),
    ...(Object.values(validation).includes('FotoSelfieBPKBOwner') && {
      FotoSelfieBPKBOwner: Yup.string().nullable().required(''),
    }),
    ...(Object.values(validation).includes('FotoSelfieBPKBOwnerSpouse') && {
      FotoSelfieBPKBOwnerSpouse: Yup.string().nullable().required(''),
    }),
  })
  //#endregion

  //#region HANDLE ON BACK
  useEffect(() => {
    history.listen(() => {
      if (history.action === 'POP') {
        history.go(0)
      }
    })
  }, [history])

  !detail && !loading && history.push(PATH.PAGE_404)
  //#endregion

  //#region FUNCTION
  const generateErrorText = (document: Document) => {
    const labelText = label[document.document_group.code as Allowed]
    if (labelText && document.status === 'revised')
      return `${labelText}: ${document.reason}`
    return ''
  }
  const uploadFile = async (key: any, file: any) => {
    const res = await FileService.create({
      app_name: 'starx',
      profile_name: '3mb',
      tags: `revise_bpkb_${key}`,
      files: file,
    }).catch((err) => err.response)
    if (!res.data.status) {
      Swal.fire({
        title: 'Terjadi Kesalahan!',
        text: res.data.message,
        icon: 'error',
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    }
    return res.data.data
  }
  //#endregion

  //#region INITIALIZE
  const initialValues: Values = {
    FotoKTPBPKBOwner: '',
    FotoSelfieBPKBOwner: '',
    FotoKTPBPKBOwnerSpouse: '',
    FotoSelfieBPKBOwnerSpouse: '',
    documents: [],
  }

  const getBpkbDetail = useCallback(async () => {
    dispatch(
      getBpkbOwnerDetail({
        form_token: token,
      }),
    )
  }, [bpkbOwnerDetail])

  useEffect(() => {
    if (detail) {
      setDocuments([
        ...detail.documents.filter(
          (d) =>
            d.status === 'revised' && allowed.includes(d.document_group.code),
        ),
        ...(detail.spouse?.documents.filter(
          (d) =>
            d.status === 'revised' && allowed.includes(d.document_group.code),
        ) ?? []),
      ])

      setError([
        ...detail.documents.map((document) => generateErrorText(document)),
        ...(detail.spouse?.documents.map(
          (document) => generateErrorText(document) ?? '',
        ) ?? []),
      ])
    }
  }, [detail])

  useEffect(() => {
    getBpkbDetail()
  }, [])
  //#endregion

  //#region HANDLE SUBMIT
  const onSubmitClick = async (
    values: Values,
    { setSubmitting, resetForm }: FormikHelpers<Values>,
  ) => {
    if (values.files) {
      for (const [key, file] of Object.entries(values.files)) {
        const getFile = await uploadFile(key, file)
        const getDocument = documents
          .filter((d) => d.document_group.code === key)
          .reduce((obj, key) => {
            obj[key] = documents[key]
            return obj
          })

        values.documents = [
          ...values.documents.filter(
            (d) =>
              d.id !== getDocument.id &&
              allowed.includes(d.document_group.code),
          ),
          {
            id: getDocument.id,
            filename: getFile.file_url,
            document_group: {
              code: getDocument.document_group.code,
            },
          },
        ]
      }
    }

    values.documents.forEach((object) => {
      delete object['document_group']
    })

    const resSubmit = await DanatunaiService.reviseBpkbOwner({
      form_token: token ?? '',
      data: {
        documents: values.documents,
      },
    })
    if (resSubmit.status === 200) {
      setSubmitted(detail.customer?.username)
      resetForm()
    } else {
      Swal.fire({
        title: 'Terjadi Kesalahan!',
        text: resSubmit.data.message,
        icon: 'error',
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (!result.isConfirmed) {
          history.go(0)
        }
      })
    }

    setSubmitting(false)
  }

  useEffect(() => {
    if (submitted)
      history.push({
        pathname: PATH.BPKB_THANK_YOU,
        state: {
          name: submitted,
        },
      })
  }, [submitted])
  //#endregion

  return (
    <div className="bpkb-owner-revise">
      <div className="bpkb-owner-revise__title">Revisi Data Pemilik BPKB</div>
      {!detail || documents.length === 0 ? (
        <p className="bpkb-owner__content" style={{ textAlign: 'center' }}>
          {loading
            ? 'Loading...'
            : documents.length === 0
            ? 'Tidak ada dokumen yang di perlu di revisi.'
            : 'Data tidak ditemukan atau token tidak valid.'}
        </p>
      ) : (
        <div className="bpkb-owner-revise__content">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitClick}>
            {({ isSubmitting, isValid, dirty }): ReactElement => (
              <Form>
                <Card title="Lampiran Dokumen" className="bpkb-owner__content">
                  {error.length !== 0 && (
                    <div className="alert alert--error">
                      <ul>
                        {error.map((err, index) => {
                          if (err !== '')
                            return <li key={`error-item-${index}`}>{err}</li>
                        })}
                      </ul>
                    </div>
                  )}
                  <div className="input-image">
                    {documents.map((document, index) => {
                      return (
                        <Field
                          key={`document-item-${index}`}
                          component={Input.Image}
                          name={document.document_group.code}
                          imageUrl={document.filename}
                          label={label[document.document_group.code as Allowed]}
                          className="input-image-item"
                          required
                          enabledEdit
                          errorIcon
                        />
                      )
                    })}
                    <div className="input-image__legend">
                      <p>*Ukuran foto maksimal 3MB.</p>
                      <p>
                        *Format foto yang diperbolehkan adalah JPG, JPEG, dan
                        PNG
                      </p>
                    </div>
                  </div>
                </Card>
                <div className="bpkb-owner__footer">
                  <Button
                    type="submit"
                    elId="ButtonSubmitBPKBOwnerRevise"
                    text="PERBARUI"
                    disabled={isSubmitting || !(isValid && dirty)}
                    loading={isSubmitting}
                    fullWidth
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  )
}
export default BPKBOwnerRevise
//#endregion
