import React from 'react'
import Icon from '@mdi/react'

interface HeaderProps {
  title: string
  icon: string
}

const AccordionHeader = ({ title, icon }: HeaderProps): React.ReactElement => {
  return (
    <div className="accordion-header">
      <span className="accordion-header-title">{title}</span>
      <span className="accordion-header-icon">
        {<Icon path={icon} size={1} />}
      </span>
    </div>
  )
}

export default AccordionHeader
