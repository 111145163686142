import React from 'react'
import { useDocumentTitle } from '../../../wrapper/utils'
import './StarXFlyer.scss'

const STARX_FLYER = process.env.REACT_APP_STARX_FLYER

const StarXFlyer: React.FC = () => {
  useDocumentTitle('StarX Flyer')

  return (
    <iframe
      src={`https://docs.google.com/viewerng/viewer?url=${STARX_FLYER}&embedded=true`}></iframe>
  )
}

export default StarXFlyer
