//#region IMPORT
import React, { useEffect, useState } from 'react'
import { Features, Header } from '../../../homepage/components'
import t from '../../../../lang'
import './Partner.scss'

import { PartnerFeaturesList } from '../../../partner/constants'
import partnerFeaturesJSON from '../../entity/partner_features.json'
import { JoinUs } from '../../components'
import { useDocumentTitle } from '../../../wrapper/utils'
//#endregion

const Partner: React.FC = () => {
  const [featuresList, setFeaturesList] = useState<PartnerFeaturesList[]>([])

  useDocumentTitle('Partner')

  useEffect(() => {
    setFeaturesList(partnerFeaturesJSON)
  }, [])

  return (
    <div className="partner">
      <Header.Custom
        className="partner-header"
        title={t(
          'Kemitraan yang sangat menguntungkan untuk memperluas pasar dan tanpa dibebankan biaya komisi',
        )}
        description={t(
          'Reach more customers and get various conveniences by becoming a Star Xperience Partner',
        )}>
        <div className="partner-image">
          <img src="./images/partner/image_pc.png" alt="" />
        </div>
      </Header.Custom>
      <Features
        title={t('Why Star Xperience?')}
        description={t(
          'Currently, the Star Xperience application has interesting features to use',
        )}
        featuresList={featuresList}
      />
      <JoinUs
        title={t('Interested in Partnering with Star Xperience?')}
        description={t('Register your bussiness right now.')}
      />
    </div>
  )
}

export default Partner
