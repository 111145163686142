//#region IMPORT
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import './Card.scss'
//#endregion

//#region INTERFACE
interface Props {
  title?: string
  children?: ReactNode | string
  className?: string
}
//#endregion

//#region MAIN
export const Card: React.FC<Props> = ({
  title,
  children,
  className,
}: Props) => {
  const classes = classNames('card', className)

  return (
    <div className={classes}>
      {title && (
        <>
          <div className="card__title">{title}</div>
          <div className="card__divider" />
        </>
      )}
      <div className="card__content">{children}</div>
    </div>
  )
}
//#endregion
