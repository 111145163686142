//#region IMPORT
import React from 'react'
import { Header } from '../../../homepage/components'
import t from '../../../../lang'
import './Product.scss'

import { ProductService } from '../../components'
import FooterPromo from '../../../promo/components/FooterPromo'
import { useDocumentTitle } from '../../../wrapper/utils'
//#endregion

const Product: React.FC = () => {
  useDocumentTitle('Layanan')

  return (
    <div className="product">
      <Header.Custom
        className="product-header"
        title={t(
          'Star Xperience merupakan aplikasi yang menyediakan berbagai kebutuhan',
        )}
        description={t(
          'Pengguna dapat melakukan pembelian, pembayaran angsuran, perawatan, pembayaran pajak tahunan, sampai dengan penjualan kembali.',
        )}>
        <div className="product-image">
          <img src="./images/product/image_phone.png" alt="" />
        </div>
      </Header.Custom>
      <ProductService title={t('Our Services:')} />
      <FooterPromo
        type={2}
        id="promo__footer"
        title={t('Apalagi yang Anda tunggu?')}
        description={t(
          'Segera jadi bagian dari StarXperience dan dapatkan banyaknya manfaat yang dapat menunjang kebutuhan hidup Anda.',
        )}></FooterPromo>
    </div>
  )
}

export default Product
