/*
 * Main Localization File, import all the individual module localization files and join it into one array here.
 */

import LocalGlobal from '../lang/LocaleGlobal'

type Lang = {
  en: string
  id: string
}

export const Locale: Lang[] = [...LocalGlobal]
