import React from 'react'

const ConvertDate = (date: Date) => {
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Agu',
    'Sep',
    'Okt',
    'Nov',
    'Des',
  ]

  const formatDate = new Date(date)
  let currentDate: any = formatDate.getDate()
  currentDate < 10 && (currentDate = '0' + currentDate)

  const convertedDate = `${currentDate} ${
    month[formatDate.getMonth()]
  } ${formatDate.getFullYear()}`

  return convertedDate
}

interface DateInterface {
  start: any
  end: any
}

const FormatDateRange = (start: any, end: any) => {
  const formatStartDate = new Date(start)
  const formatEndDate = new Date(end)

  const dateRange = `${ConvertDate(formatStartDate)} - ${ConvertDate(
    formatEndDate,
  )}`

  return dateRange
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const DateRange: React.FC<DateInterface> = ({ start, end }: DateInterface) => {
  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>{FormatDateRange(start, end)}</>
  )
}

export default DateRange
