//#region IMPORT
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import {
  CardIcon,
  DateIcon,
  EmailIcon,
  UserIcon,
} from '../../../../assets/icon'
import { Button, Card, Input } from '../../../../components'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import './BPKBOwnerSpouse.scss'
import { parseISO } from 'date-fns'
import FileService from '../../../../services/fileService/http'
import Swal from 'sweetalert2'
import { useHistory, useLocation } from 'react-router-dom'
import DanatunaiService from '../../../../services/danaTunai/http'
import { useDocumentTitle, base64ToBlob } from '../../../wrapper/utils'
import { BPKBOwnerProps, Spouse } from '../../constants/types'
import { PATH } from '../../../wrapper/constants'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
  bpkbOwnerSelector,
  getBpkbOwnerDetail,
} from '../../../../redux/bpkbOwner'
//#endregion

//#region INTERFACE
type Values = {
  bpkb_owner: string
  email: string
  phone: string | number
  date_of_birth: string | Date
  married_status: string
  identity_number: string
  identity_type: string
  id: number
  documents: any[]
  FotoKTPBPKBOwnerSpouse?: string
  FotoSelfieBPKBOwnerSpouse?: string
  files?: {
    FotoKTPBPKBOwnerSpouse: any
    FotoSelfieBPKBOwnerSpouse: any
  }
  customer?: {
    username: string
  }
}
type Allowed = 'FotoKTPBPKBOwnerSpouse' | 'FotoSelfieBPKBOwnerSpouse'
//#endregion

//#region VALIDATION
const validationSchema = Yup.object().shape({
  bpkb_owner: Yup.string().nullable().required('Nama harus diisi.'),
  identity_number: Yup.string()
    .nullable()
    .min(16, 'Silakan masukkan sesuai format NIK')
    .max(16)
    .required('NIK harus diisi.'),
  email: Yup.string()
    .nullable()
    .email('Silakan masukkan sesuai format email')
    .required('Email harus diisi.'),
  phone: Yup.string()
    .nullable()
    .min(8, 'Masukkan min. 8 karakter')
    .max(12)
    .required('No. Telepon harus diisi.'),
  date_of_birth: Yup.date()
    .nullable()
    .typeError('Masukkan tanggal lahir dengan format DD/MM/YYY.')
    .required('Tanggal lahir harus diisi.'),
  married_status: Yup.string().nullable().required('Status kawin harus diisi.'),
  FotoKTPBPKBOwnerSpouse: Yup.string()
    .nullable()
    .required('Foto KTP harus diisi.'),
})
//#endregion

//#region MAIN
const BPKBOwnerSpouse: React.FC = () => {
  useDocumentTitle('BPKB Owner Spouse')
  const dispatch = useAppDispatch()
  const history = useHistory()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const { bpkbOwnerDetail, loading } = useAppSelector(bpkbOwnerSelector)
  const bpkbOwner: BPKBOwnerProps = bpkbOwnerDetail.data
  const spouse: Spouse = bpkbOwnerDetail.data?.spouse
  const [submitted, setSubmitted] = useState<string>()
  const [faceResult, setFaceResult] = useState('')
  const { state: data }: any = useLocation()

  const isMobile = urlParams.get('mobile')
  const allowed = ['FotoKTPBPKBOwnerSpouse', 'FotoSelfieBPKBOwnerSpouse']
  const getDetectionResult = localStorage.getItem(
    'BPKB_OWNER_SPOUSE_LIVENESS_DATA',
  )
  const detectionResult = getDetectionResult
    ? JSON.parse(getDetectionResult)
    : ''

  //#region HANDLE ERROR
  // !data && history.push(PATH.PAGE_404)
  //#endregion

  //#region INITIALIZE
  const getBpkbDetail = useCallback(async () => {
    dispatch(
      getBpkbOwnerDetail({
        form_token: data?.form_token,
      }),
    )
  }, [bpkbOwnerDetail])

  useEffect(() => {
    data?.form_token && getBpkbDetail()

    if (data && !detectionResult) {
      history.push({
        pathname: PATH.LIVENESS,
        search: !isMobile
          ? `?redirect_to=BPKB_OWNER_SPOUSE&token=${data?.form_token}`
          : `?redirect_to=BPKB_OWNER_SPOUSE&token=${data?.form_token}&mobile=${isMobile}`,
      })
    }
  }, [data])

  const initialValues: Values = {
    bpkb_owner: spouse?.bpkb_owner ?? '',
    email: spouse?.email ?? '',
    phone: spouse?.phone ? parseInt(spouse?.phone) : '',
    date_of_birth: spouse?.date_of_birth ? new Date(spouse?.date_of_birth) : '',
    married_status: 'MARRIED',
    identity_number: spouse?.identity_number ?? '',
    identity_type: spouse?.identity_type ?? '',
    id: spouse?.id ?? 0,
    documents: spouse?.documents ?? [],
    FotoKTPBPKBOwnerSpouse: '',
    FotoSelfieBPKBOwnerSpouse: '',
  }
  //#endregion

  //#region FUNCTION
  const uploadFile = async (key: any, file: any) => {
    const res = await FileService.create({
      app_name: 'starx',
      profile_name: '3mb',
      tags: `${spouse?.bpkb_owner}_bpkb_${key}`,
      files: file,
    }).catch((err) => err.response)
    if (!res.data.status) {
      Swal.fire({
        title: 'Terjadi Kesalahan!',
        text: res.data.message,
        icon: 'error',
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    }
    return res.data.data
  }
  //#endregion

  //#region SUBMIT
  const onSubmitClick = async (
    values: Values,
    { setSubmitting, resetForm }: FormikHelpers<Values>,
  ) => {
    const generatedSelfieBPKBOwner = new File(
      [base64ToBlob(faceResult)],
      'image.jpg',
      {
        type: 'image/jpeg',
      },
    )
    if (typeof values.files !== 'undefined') {
      values.files['FotoSelfieBPKBOwnerSpouse'] = generatedSelfieBPKBOwner
    } else {
      values.files = {
        FotoKTPBPKBOwnerSpouse: undefined,
        FotoSelfieBPKBOwnerSpouse: generatedSelfieBPKBOwner,
      }
    }
    if (!values.files['FotoKTPBPKBOwnerSpouse']) {
      delete values.files.FotoKTPBPKBOwnerSpouse
    }

    if (values.files) {
      for (const [key, file] of Object.entries(values.files)) {
        const getFile = await uploadFile(key, file)
        const getDocument = values.documents
          .filter((d) => d.document_group.code === key)
          .reduce((obj, key) => {
            obj[key] = values.documents[key]
            return obj
          })

        values.documents = [
          ...values.documents.filter(
            (d) =>
              d.id !== getDocument.id &&
              allowed.includes(d.document_group.code),
          ),
          {
            id: getDocument.id,
            filename: getFile.file_url,
            document_group: {
              code: getDocument.document_group.code,
            },
          },
        ]
      }
    }

    values.documents.forEach((object) => {
      delete object['document_group']
    })

    const resSubmit = await DanatunaiService.updateBpkbOwnerSpouse({
      form_token: data?.form_token ?? '',
      data: {
        ...(values && values),
      },
    })
    if (typeof resSubmit !== 'undefined') {
      if (resSubmit.status === 200) {
        localStorage.removeItem('BPKB_OWNER_SPOUSE_LIVENESS_DATA')
        setSubmitted(bpkbOwner.customer?.username)
        resetForm()
      } else {
        Swal.fire({
          title: 'Terjadi Kesalahan!',
          text: resSubmit.data.message,
          icon: 'error',
          showConfirmButton: false,
          showDenyButton: true,
          denyButtonText: 'Ok',
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            history.go(0)
          }
        })
      }

      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (submitted)
      history.push({
        pathname: PATH.BPKB_THANK_YOU,
        state: {
          name: submitted,
        },
      })
  }, [submitted])
  // #endregion

  return (
    <div className="bpkb-owner-spouse">
      <div className="bpkb-owner-spouse__title">BPKB - Data Pasangan</div>

      {!data && !spouse && !loading ? (
        <p
          className="bpkb-owner-spouse__content"
          style={{ textAlign: 'center' }}>
          Data tidak ditemukan atau token tidak valid.
        </p>
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitClick}>
          {({ isSubmitting, isValid }): ReactElement => {
            return (
              <Form>
                <Card
                  title="Biodata Diri"
                  className="bpkb-owner-spouse__content">
                  <Field
                    component={Input.Text}
                    name="bpkb_owner"
                    label="Nama"
                    placeholder="Silakan isi nama Anda"
                    className="form-item"
                    iconLeft={<UserIcon />}
                    autoComplete="off"
                  />
                  <Field
                    component={Input.Number}
                    name="identity_number"
                    label="NIK"
                    placeholder="Silakan isi NIK Anda"
                    className="form-item"
                    maxLength={16}
                    iconLeft={<CardIcon />}
                    autoComplete="off"
                  />
                  <Field
                    component={Input.Text}
                    name="email"
                    label="Email"
                    placeholder="Silakan isi email Anda"
                    className="form-item"
                    iconLeft={<EmailIcon />}
                    autoComplete="off"
                  />
                  <Field
                    component={Input.Phone}
                    name="phone"
                    label="No. Telepon"
                    placeholder="82123456789"
                    className="form-item"
                    maxLength={12}
                    iconLeft="+62"
                    autoComplete="off"
                  />
                  <Field
                    component={Input.DatePicker}
                    name="date_of_birth"
                    label="Tanggal Lahir"
                    placeholder="Silakan isi tanggal lahir Anda"
                    className="form-item"
                    maxLength={13}
                    iconLeft={<DateIcon />}
                    initialStartDate={
                      spouse?.date_of_birth
                        ? parseISO(spouse.date_of_birth)
                        : ''
                    }
                    maxDate={new Date()}
                  />
                </Card>
                <Card
                  title="Lampiran Dokumen"
                  className="bpkb-owner-spouse__content">
                  <div className="input-image">
                    {spouse?.documents
                      .filter((d) => allowed.includes(d.document_group.code))
                      .map((document, i) => {
                        const label = {
                          FotoKTPBPKBOwnerSpouse: 'KTP',
                          FotoSelfieBPKBOwnerSpouse: 'Selfie&KTP',
                        }

                        let photoUrl = document.filename
                        let photoFromLiveness = false
                        if (
                          String(document.document_group.code) ==
                          'FotoSelfieBPKBOwnerSpouse'
                        ) {
                          photoUrl = detectionResult?.face_2 ?? photoUrl

                          photoFromLiveness = true
                          setFaceResult(photoUrl)
                        }

                        return document.document_group.code ==
                          'FotoSelfieBPKBOwnerSpouse' ? (
                          <Field
                            key={`document-item-${i}`}
                            component={Input.Image}
                            name={document.document_group.code}
                            imageUrl={photoUrl}
                            label={
                              label[document.document_group.code as Allowed]
                            }
                            disableDelete={photoFromLiveness}
                            className="input-image-item"
                          />
                        ) : (
                          <Field
                            key={`document-item-${i}`}
                            component={Input.Image}
                            name={document.document_group.code}
                            label={
                              label[document.document_group.code as Allowed]
                            }
                            className="input-image-item"
                          />
                        )
                      })}

                    <div className="input-image__legend">
                      <p>*Ukuran foto maksimal 3MB.</p>
                      <p>
                        *Format foto yang diperbolehkan adalah JPG, JPEG, dan
                        PNG
                      </p>
                    </div>
                  </div>
                </Card>
                <div className="bpkb-owner-spouse__footer">
                  <Button
                    type="submit"
                    elId="ButtonSubmitBPKBOwnerSpouse"
                    text="SIMPAN"
                    disabled={isSubmitting || !isValid}
                    loading={isSubmitting}
                    fullWidth
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

export default BPKBOwnerSpouse
//#endregion
