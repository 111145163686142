//#region IMPORT
import React from 'react'
import DanaTunaiDescIllustration from '../../../../assets/danatunai_content.png'
import DanaTunaiCircleIllustration from '../../../../assets/danatunai_content_circle.png'
import ChairIllustration from '../../../../assets/3d-chair.png'
import EducationIllustration from '../../../../assets/3d-education.png'
import ElectronicIllustration from '../../../../assets/3d-electronic.png'
import HealthIllustration from '../../../../assets/3d-health.png'
import './DanaTunaiDesc.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DanaTunaiDesc = () => {
  const illustrationListMobile = [
    {
      image: ChairIllustration,
      title: 'Renovasi atau Furniture',
    },
    {
      image: ElectronicIllustration,
      title: 'Barang Elektronik',
    },
    {
      image: EducationIllustration,
      title: 'Renovasi atau Furniture',
    },
    {
      image: HealthIllustration,
      title: 'Biaya Kesehatan',
    },
  ]
  return (
    <div className="desc-dana-tunai">
      <div className="desc-dana-tunai__content">
        <div className="desc-dana-tunai__text">
          <h2 className="desc-dana-tunai__title">
            Apa itu <span className="accent">Dana Tunai?</span>
          </h2>
          <p className="desc-dana-tunai__desc">
            Fasilitas Dana Tunai merupakan fasilitas pinjaman khusus bagi Anda
            Pelanggan Dipo Star Finance yang membutuhkan dana untuk memenuhi
            berbagai macam kebutuhan konsumtif
          </p>
        </div>
        <div className="desc-dana-tunai__illustration">
          <img src={DanaTunaiDescIllustration} />
          <div className="spinning-circle-container">
            <img src={DanaTunaiCircleIllustration} />
          </div>

          <div className="desc-dana-tunai__illustration-mobile">
            {illustrationListMobile.map((item: any, index: number) => (
              <div
                key={`illustration-card-${index}`}
                className="desc-dana-tunai__illustration-mobile-card">
                <img src={item.image} alt={`Illustration ${index}`} />
                <p>{item.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DanaTunaiDesc
