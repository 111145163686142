//#region IMPORT
import React, { useEffect, useState } from 'react'
import { ErrorContent } from '../../components'
import t from '../../../../lang'
import queryString from 'query-string'

import './Error.scss'

import IconError404 from '../../../../assets/illustration_404.svg'
import IconError500 from '../../../../assets/illustration_500.svg'
import { useHistory, useLocation } from 'react-router-dom'
import { ERROR_CODE } from '../../constants'
import { externalLink, useDocumentTitle } from '../../../wrapper/utils'
import { Env } from '../../../../config/Config'
import { PATH } from '../../../wrapper/constants'
//#endregion

//#region INTERFACE
//#endregion

//#region MAIN
export const Error: React.FC = () => {
  const [status, setStatus] = useState(ERROR_CODE.STATUS_404)
  const location = useLocation()
  const errorParams = queryString.parse(location.search)
  const external = errorParams.code !== undefined

  useDocumentTitle(status)
  if (status == ERROR_CODE.STATUS_404) {
    useDocumentTitle('Halaman tidak ditemukan')
  } else if (status == ERROR_CODE.STATUS_500) {
    useDocumentTitle('Terjadi kesalahan server')
  }

  const history = useHistory()
  const buttonOnClick = () => {
    external
      ? externalLink(Env.base_url + PATH.CMS_HOME, 'FROM_ERROR_PAGE')
      : history.push(PATH.HOME)
  }

  useEffect(() => {
    errorParams.code === ERROR_CODE.STATUS_500
      ? setStatus(ERROR_CODE.STATUS_500)
      : ERROR_CODE.STATUS_404
  }, [])

  return (
    <div className="error">
      {status == ERROR_CODE.STATUS_404 && (
        <ErrorContent
          illustration={IconError404}
          title={t('Page Not Found :(')}
          buttonOnClick={buttonOnClick}
        />
      )}
      {status == ERROR_CODE.STATUS_500 && (
        <ErrorContent
          illustration={IconError500}
          title={t('Internal Server Error :(')}
          buttonOnClick={buttonOnClick}
        />
      )}
    </div>
  )
}
//#endregion
