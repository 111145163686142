//#region IMPORT
import React, { useEffect, useState } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import classnames from 'classnames'
import './OurService.scss'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import { ServiceList } from '../../constants'
import { useWindowDimensions } from '../../../wrapper/utils'
import parse from 'html-react-parser'
//#endregion

//#region MAIN COMPONENT
//#region INTERFACE
type Props = {
  className?: string
  id?: string
  title?: string
  serviceList: ServiceList[]
}
//#endregion

SwiperCore.use([Navigation])

const OurService: React.FC<Props> = ({
  className,
  id,
  title,
  serviceList,
}: Props) => {
  //#region CLASSES
  const classes = classnames('service', className)
  //#endregion

  const { width } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    width < 992 ? setIsMobile(true) : setIsMobile(false)
  })

  //#region FEATURES ITEM
  const ServiceItem = (imageUrl?: string, label?: string, caption?: string) => {
    return (
      <div className="service-item">
        <div className="service-item__label">{label && label}</div>
        <div className="service-item__caption">{caption && parse(caption)}</div>
        <div className="service-item__image">
          {imageUrl && <img src={imageUrl} alt={label} />}
        </div>
      </div>
    )
  }
  //#endregion

  return (
    <div id={id} className={classes}>
      <div className="service__title">{title}</div>
      <div className="service__content">
        <Swiper
          navigation
          spaceBetween={isMobile ? 16 : 24}
          slidesPerView={1.15}
          breakpoints={{
            768: {
              slidesPerView: 2.5,
            },
            1200: {
              slidesPerView: 3.5,
            },
          }}>
          {serviceList.map((item, index) => (
            <SwiperSlide key={`service-item${index}`}>
              {ServiceItem(item.imageUrl, item.label, item.caption)}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default OurService

//#endregion
