import { createReducer } from '@reduxjs/toolkit'
import { getBanner } from './actions'

const initialState: any = {
  banner: [],
  loading: true,
}
export const bannerReducer = createReducer(initialState, (builder) => {
  // GET BANNER
  builder.addCase(getBanner.pending, (state) => {
    state.loading = true
  })
  builder.addCase(getBanner.fulfilled, (state, { payload }) => {
    state.banner = payload
    state.loading = false
  })
  builder.addCase(getBanner.rejected, (state) => {
    state.loading = false
  })
})

export default bannerReducer
