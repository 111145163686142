//#region IMPORT
import React from 'react'
import { Location, ServicePhotos } from '../../constants'
import ImageEmpty from '../../../../assets/image-empty.svg'
import './ServiceProviderItem.scss'
import Icon from '@mdi/react'
import { mdiMapMarker, mdiStar } from '@mdi/js'
//#endregion

//#region INTERFACE
interface Props {
  images?: ServicePhotos[]
  name?: string
  categories?: string
  rating?: number
  location: Location
}
//#endregion

//#region MAIN
const checkImageUrl = (url: string) => {
  const ext = url.split('.').pop()
  const valid = ext === 'jpg'
  return valid
}

export const ServiceProviderItem: React.FC<Props> = ({
  images,
  name,
  categories,
  rating,
  location,
}: Props) => {
  return (
    <div
      className="service-provider-item"
      data-aos="fade-in"
      data-aos-duration={500}>
      <div className="service-provider-item__image">
        {images &&
          images.map(
            (item, index) =>
              index === 0 &&
              (checkImageUrl(item.url) ? (
                <img
                  key={`image-${index}`}
                  src={item.url}
                  alt={`${name}-${index}`}
                />
              ) : (
                <img src={ImageEmpty} alt="empty-image" />
              )),
          )}

        {images?.length === 0 && <img src={ImageEmpty} alt="empty-image" />}
      </div>
      <div className="service-provider-item__title">{name}</div>
      <div className="service-provider-item__description">
        {categories}
        <span>
          <Icon path={mdiMapMarker} size={0.5} /> {location.city_name || '-'}
        </span>
      </div>
      <div className="service-provider-item__rating">
        <Icon path={mdiStar} /> {rating}
      </div>
    </div>
  )
}
//#endregion
