//#region IMPORT
import React, { useState, useEffect } from 'react'
import Accordion from '../../../promo/components/Accordion'
import { Tab, Tabs } from 'mui-tabs'
import FAQIllustration from '../../../../assets/3d-faq.png'
import parse from 'html-react-parser'
import { useWindowDimensions } from '../../../wrapper/utils'
import './DanaTunaiInfo.scss'

interface Props {
  removedEl?: string
  datas: {
    title: string
    content: string
  }[]
}

export const DanaTunaiInfo: React.FC<Props> = ({
  removedEl = '',
  datas,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const { width } = useWindowDimensions()

  useEffect(() => {
    width < 767 && setIsMobile(true)
  }, [])

  return (
    <div className="info-dana-tunai">
      {/* Showed up on Desktop Only */}
      {!isMobile && (
        <div className="info-dana-tunai-desktop">
          <div className="info-dana-tunai__card">
            <h2 className="info-dana-tunai__card-title">Informasi Tambahan</h2>
            <div className="info-dana-tunai__content">
              <div className="info-dana-tunai__content-left">
                <Tabs
                  value={selectedIndex}
                  onChange={(value) => setSelectedIndex(value)}>
                  {datas.length > 0 &&
                    datas.map((item: any, index: number) => (
                      <Tab
                        key={`tab-${index}`}
                        value={index}
                        label={item.title}
                      />
                    ))}
                </Tabs>

                <div className="info-dana-tunai__card-illustration">
                  <img src={FAQIllustration} alt="FAQ Icon" />
                </div>
              </div>
              <div className="info-dana-tunai__content-right tabs-content">
                {datas.length > 0 && parse(datas[selectedIndex]['content'])}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* End Desktop */}

      {/* Showed up on Tablet & Mobile */}
      {isMobile && (
        <div className="info-dana-tunai-mobile">
          <div className="info-dana-tunai-mobile__header">
            <img
              className="info-dana-tunai-mobile__header-icon"
              src={FAQIllustration}
              alt="FAQ Icon"
            />
            <h2 className="info-dana-tunai-mobile__header-text">
              <span className="accent">Informasi</span> Tambahan
            </h2>
          </div>
          <div className="info-dana-tunai-mobile__content">
            <Accordion removedEl={removedEl} datas={datas} />
          </div>
        </div>
      )}
      {/* End Tablet & Mobile */}
    </div>
  )
}

export default DanaTunaiInfo
