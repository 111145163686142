//#region IMPORT
import React from 'react'
import './PromoBannerInfo.scss'
import Icon from '@mdi/react'
import { Button } from '../../../../components'
import { mdiMapMarker, mdiCalendar } from '@mdi/js'
import DateRange from '../../components/DateRange'
import BranchList from '../BranchList'
import { externalLink } from '../../../wrapper/utils'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ImageEmpty from '../../../../assets/image-empty.svg'

interface Props {
  is_loading?: boolean
  image_url: any
  post_images: any
  branch_area: any
  start_date: any
  end_date: any
  is_mobile: boolean
  ajukan_link: string
}

const displayBannerImage = (post_img: any, img_url: any) => {
  if (post_img && post_img.length > 0) {
    const selectedImage = post_img.find((obj: any) => {
      return obj.image_type_id == 2
    })
    const imageUrl = selectedImage?.['image_url'] ?? ImageEmpty

    return imageUrl
  } else if (img_url) {
    return img_url
  }
}

const PromoBannerInfo: React.FC<Props> = ({
  is_loading,
  image_url,
  post_images,
  branch_area,
  start_date,
  end_date,
  is_mobile,
  ajukan_link,
}: Props) => {
  return (
    <div className="promo-banner-info__container">
      <div className="key-visual__container">
        {is_loading ? (
          <Skeleton
            width={is_mobile ? 200 : 400}
            height={is_mobile ? 200 : 220}
          />
        ) : (
          <img
            loading="lazy"
            src={displayBannerImage(post_images, image_url)}
            alt="promo_image"
          />
        )}
      </div>

      <div className="promo-banner-info__text">
        <Icon path={mdiMapMarker} size={1}></Icon>
        <span>
          {is_loading ? (
            <Skeleton width={200} />
          ) : (
            <BranchList branches={branch_area} displayFull={true} />
          )}
        </span>
      </div>
      <div className="promo-banner-info__text">
        <Icon path={mdiCalendar} size={1}></Icon>
        <span>
          {is_loading ? (
            <Skeleton width={200} />
          ) : (
            <DateRange start={start_date} end={end_date} />
          )}
        </span>
      </div>

      {ajukan_link && (
        <Button
          elId="buttonAjukan"
          variant="contained"
          fullWidth={true}
          className="promo-banner-info__open-app"
          text="AJUKAN DALAM APLIKASI"
          color="tertiary"
          onClick={() => {
            externalLink(ajukan_link, 'deep-link-redirect')
          }}
        />
      )}
    </div>
  )
}

export default PromoBannerInfo
