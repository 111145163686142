import { createAsyncThunk } from '@reduxjs/toolkit'
import BlogService from '../../services/blogService/http'
import { BannerParams } from '../../services/blogService/types'

export const getBanner = createAsyncThunk(
  'banner',
  async (params: BannerParams) => {
    const response = await BlogService.getBanner(params).catch(
      (err) => err.response,
    )
    return response.data.data
  },
)
