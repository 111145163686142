//#region IMPORT
import classNames from 'classnames'
import React, { ReactNode, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import id from 'date-fns/locale/id'

import 'react-datepicker/dist/react-datepicker.css'
import './Input.scss'
import { FieldProps } from 'formik'
import Icon from '@mdi/react'
import { mdiCameraPlus, mdiDelete, mdiPencil } from '@mdi/js'
import Swal from 'sweetalert2'
import { resizeImage } from '../../utils/resizeImage'
import { Modal } from '..'
//#endregion

//#region INTERFACE
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
  iconLeft?: ReactNode | string
  helperText?: ReactNode | string
  label?: string
  field?: any
  form?: any
  onEnter?(): void
  onChangeValue?(): void
}
//#endregion

//#region MAIN
export const Text: React.FC<Props & FieldProps> = ({
  className,
  iconLeft,
  helperText,
  label,
  field,
  form,
  onEnter,
  onChangeValue,
  ...others
}: Props) => {
  const classes = classNames(
    'form-control',
    { 'form-control--error': form?.errors[field.name] !== undefined },
    { 'form-control--with-icon-left': iconLeft },
    className,
  )
  return (
    <div className={classes}>
      {label && (
        <label
          htmlFor={field?.name}
          className={others.required ? 'required' : ''}>
          {label}
        </label>
      )}
      <div className="input-group">
        {iconLeft && <div className="icon-left">{iconLeft}</div>}
        <input
          type="text"
          name={field?.name}
          value={field?.value}
          onChange={(e): void => {
            const val = e.currentTarget.value
            onChangeValue && onChangeValue()
            form?.setFieldTouched(field?.name, true)
            form?.setFieldValue(field?.name, val)
          }}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>): void => {
            const key = e.keyCode ? e.keyCode : e.charCode

            if (onEnter && key === 13) {
              onEnter()
            }
          }}
          {...others}
        />
      </div>
      <p>{form?.errors[field.name] || helperText}</p>
    </div>
  )
}

export const Number: React.FC<Props> = ({
  className,
  label,
  iconLeft,
  helperText,
  form,
  field,
  onEnter,
  onChangeValue,
  ...others
}: Props) => {
  const classes = classNames(
    'form-control',
    { 'form-control--error': form?.errors[field.name] !== undefined },
    { 'form-control--with-icon-left': iconLeft },
    className,
  )
  return (
    <div className={classes}>
      {label && (
        <label
          htmlFor={field?.name}
          className={others.required ? 'required' : ''}>
          {label}
        </label>
      )}
      <div className="input-group">
        {iconLeft && <div className="icon-left">{iconLeft}</div>}
        <NumberFormat
          placeholder={others.placeholder}
          maxLength={others.maxLength}
          className="input"
          name={field?.name}
          value={field?.value}
          inputMode="numeric"
          onValueChange={(values): void => {
            const val = values.value
            onChangeValue && onChangeValue()
            form?.setFieldTouched(field?.name, true)
            form?.setFieldValue(field?.name, val)
          }}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>): void => {
            const key = e.keyCode ? e.keyCode : e.charCode

            if (onEnter && key === 13) {
              onEnter()
            }
          }}
          autoComplete={others.autoComplete}
        />
      </div>
      <p>{form?.errors[field.name] || helperText}</p>
    </div>
  )
}

export const Phone: React.FC<Props> = ({
  className,
  label,
  iconLeft,
  form,
  field,
  helperText,
  onChangeValue,
  onEnter,
  ...others
}: Props) => {
  const classes = classNames(
    'form-control',
    'form-control--phone',
    { 'form-control--error': form?.errors[field.name] !== undefined },
    { 'form-control--with-icon-left': iconLeft },
    className,
  )
  return (
    <div className={classes}>
      {label && (
        <label
          htmlFor={field?.name}
          className={others.required ? 'required' : ''}>
          {label}
        </label>
      )}
      <div className="input-group">
        <NumberFormat
          placeholder={others.placeholder}
          maxLength={others.maxLength}
          allowLeadingZeros={false}
          className="input"
          name={field?.name}
          value={field?.value}
          inputMode="numeric"
          onValueChange={(values): void => {
            const val = values.value
            onChangeValue && onChangeValue()
            form?.setFieldTouched(field?.name, true)
            form?.setFieldValue(field?.name, val)
          }}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>): void => {
            const key = e.keyCode ? e.keyCode : e.charCode

            if (onEnter && key === 13) {
              onEnter()
            }
          }}
          autoComplete={others.autoComplete}
        />
        {iconLeft && <div className="icon-left">{iconLeft}</div>}
      </div>
      <p>{form?.errors[field.name] || helperText}</p>
    </div>
  )
}

interface DateProps extends Props {
  initialStartDate?: Date
  maxDate?: Date
  minDate?: Date
}

export const DatePicker: React.FC<DateProps> = ({
  className,
  label,
  iconLeft,
  form,
  field,
  helperText,
  onChangeValue,
  initialStartDate,
  minDate,
  maxDate,
  ...others
}: DateProps) => {
  const classes = classNames(
    'form-control',
    { 'form-control--error': form?.errors[field.name] !== undefined },
    { 'form-control--with-icon-left': iconLeft },
    className,
  )
  const [startDate, setStartDate] = useState<Date>(
    initialStartDate || new Date(),
  )
  registerLocale('id', id)

  return (
    <div className={classes}>
      {label && (
        <label
          htmlFor={field?.name}
          className={others.required ? 'required' : ''}>
          {label}
        </label>
      )}
      <div className="input-group">
        {iconLeft && <div className="icon-left">{iconLeft}</div>}
        <ReactDatePicker
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
          placeholderText={others.placeholder}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          locale={id}
          dateFormat="dd/MM/yyyy"
          className="input"
          autoComplete="off"
          value={field?.value}
          name={field?.name}
          onKeyDown={(e) => {
            e.preventDefault()
          }}
          minDate={minDate}
          maxDate={maxDate}
          onSelect={(values): void => {
            onChangeValue && onChangeValue()
            form?.setFieldTouched(field?.name, true)
            form?.setFieldValue(field?.name, values)
          }}
        />
      </div>
      <p>{form?.errors[field.name] || helperText}</p>
    </div>
  )
}

interface RadioProps extends Props {
  options: {
    label: string
    value: string
  }[]
}

export const Radio: React.FC<RadioProps> = ({
  className,
  field,
  form,
  helperText,
  label,
  options,
  onChangeValue,
  onEnter,
  ...others
}: RadioProps) => {
  const classes = classNames(
    'form-control',
    { 'form-control--error': form?.errors[field.name] !== undefined },
    className,
  )
  return (
    <div className={classes}>
      {label && (
        <label
          htmlFor={field?.name}
          className={others.required ? 'required' : ''}>
          {label}
        </label>
      )}
      <div className="radio">
        {options.map((item, i) => (
          <div className="radio-item" key={`radio-item-${i}`}>
            <input
              id={`radio-item-${i}`}
              type="radio"
              name={field?.name}
              value={item.value}
              checked={item.value === field?.value}
              onChange={(e): void => {
                const val = e.currentTarget.value
                onChangeValue && onChangeValue()
                form?.setFieldTouched(field?.name, true)
                form?.setFieldValue(field?.name, val)
              }}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>): void => {
                const key = e.keyCode ? e.keyCode : e.charCode

                if (onEnter && key === 13) {
                  onEnter()
                }
              }}
              {...others}
            />
            <label htmlFor={`radio-item-${i}`}>{item.label}</label>
          </div>
        ))}
      </div>
      <p>{form?.errors[field.name] || helperText}</p>
    </div>
  )
}

interface ImageProps extends Props {
  imageUrl?: string
  enabledEdit?: boolean
  disableDelete?: boolean
  errorIcon?: boolean
}
export const Image: React.FC<ImageProps> = ({
  className,
  form,
  field,
  label,
  helperText,
  onChange,
  imageUrl,
  enabledEdit,
  disableDelete,
  errorIcon,
  ...others
}: ImageProps) => {
  const classes = classNames(
    'form-control',
    'form-control--image',
    { 'form-control--error': form?.errors[field.name] !== undefined },
    className,
  )
  const [selectedImage, setSelectedImage] = useState()
  const [image, setImage] = useState(imageUrl)
  const imageRef = useRef<HTMLInputElement>(null)
  const [showModal, setShowModal] = useState(false)
  const onImageClick = () => {
    imageRef.current?.click()
  }
  const removeSelectedImage = () => {
    form.setFieldValue(`files.${field?.name}`, undefined)
    form.setFieldValue(field?.name, '')
    setSelectedImage(undefined)
  }
  const removeImageUrl = () => {
    setImage(undefined)
    removeSelectedImage()
  }

  const toggleModal = () => setShowModal(!showModal)

  return (
    <div className={classes}>
      {errorIcon && <div className="input-alert mdi mdi-alert-circle"></div>}
      {image ? (
        <div className="input-preview">
          {enabledEdit ? (
            <div className="input-preview__edit" onClick={onImageClick}>
              <Icon path={mdiPencil} />
            </div>
          ) : (
            ''
          )}
          {!disableDelete ? (
            <div className="input-preview__delete" onClick={removeImageUrl}>
              <Icon path={mdiDelete} />
            </div>
          ) : (
            ''
          )}
          <div className="input-image" onClick={toggleModal}>
            <img
              src={selectedImage ? URL.createObjectURL(selectedImage) : image}
              alt=""
            />
          </div>
        </div>
      ) : selectedImage ? (
        <div className="input-preview">
          {enabledEdit ? (
            <div className="input-preview__edit" onClick={onImageClick}>
              <Icon path={mdiPencil} />
            </div>
          ) : (
            ''
          )}
          {!disableDelete ? (
            <div
              className="input-preview__delete"
              onClick={removeSelectedImage}>
              <Icon path={mdiDelete} />
            </div>
          ) : (
            ''
          )}
          <div className="input-image" onClick={toggleModal}>
            <img src={URL.createObjectURL(selectedImage)} alt="" />
          </div>
        </div>
      ) : (
        <div className="input-image" onClick={onImageClick}>
          <Icon path={mdiCameraPlus} size={2} />
        </div>
      )}

      <input
        ref={imageRef}
        type={image && !enabledEdit ? 'text' : 'file'}
        name={field?.name}
        value={field?.value}
        onChange={async (e: any) => {
          if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const accepted = ['jpg', 'png', 'jpeg']
            const ext = file.name.split('.').pop()

            form.setFieldValue(field?.name, e.target.value)

            if (accepted.includes(ext.toLowerCase())) {
              if (file.size > 3000000) {
                const image = (await resizeImage(file, 'JPEG', 'file')) as any
                if (image.size < 3000000) {
                  setSelectedImage(image)
                  form.setFieldValue(`files.${field?.name}`, image)
                } else {
                  removeSelectedImage()
                  Swal.fire({
                    title: 'Terjadi Kesalahan!',
                    text: `Ukuran file terlalu besar. maksimal 3mb.`,
                    icon: 'error',
                    showConfirmButton: false,
                    showDenyButton: true,
                    denyButtonText: 'Ok',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  })
                }
              } else {
                setSelectedImage(file)
                form.setFieldValue(`files.${field?.name}`, file)
                form.setFieldValue(field?.name, e.target.value)
              }
            } else {
              removeSelectedImage()
              Swal.fire({
                title: 'Terjadi Kesalahan!',
                text: `Format file tidak sesuai. Silakan pilih ${accepted.toString()}.`,
                icon: 'error',
                showConfirmButton: false,
                showDenyButton: true,
                denyButtonText: 'Ok',
                allowEscapeKey: false,
                allowOutsideClick: false,
              })
            }
          } else {
            removeSelectedImage()
          }
          onChange && onChange(e)
        }}
        {...others}
        style={{ display: 'none' }}
      />
      {label && (
        <label
          htmlFor={field?.name}
          className={others.required ? 'required' : ''}>
          {label}
        </label>
      )}
      <p>{form?.errors[field.name] || helperText}</p>
      {(selectedImage || image) && (
        <Modal.Image
          show={showModal}
          imageUrl={selectedImage ? URL.createObjectURL(selectedImage) : image}
          onConfirm={toggleModal}
        />
      )}
    </div>
  )
}
//#endregion
