//#region IMPORT
import React, { useEffect, useState } from 'react'
import { mdiMenuDown } from '@mdi/js'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useWindowDimensions } from '../../../wrapper/utils'
import './NavbarItem.scss'
import Icon from '@mdi/react'
//#endregion

//#region MAIN COMPONENT
//#region INTERFACE
interface SubmenuOptions {
  title: string
  path: any
  onClick?(): void
}

interface SubmenuList extends Array<SubmenuOptions> {}

type Props = {
  className?: string
  title: string
  isActive?: boolean
  onClick?(): void
  submenu?: SubmenuList
}
//#endregion

const NavbarItem: React.FC<Props> = ({
  className,
  title,
  isActive,
  onClick,
  submenu,
}: Props) => {
  //#region CLASSES
  const { width } = useWindowDimensions()
  const pathname = useLocation().pathname
  const classes = classnames('navbar-menu-item', {
    'navbar-menu-item--active': isActive,
    className,
  })

  const [listSubmenu, setListSubmenu] = useState<any>()
  const [isMobile, setIsMobile] = useState(false)

  const expandCollapsible = (e: any) => {
    const navbarSubmenu = e.target.childNodes[1]
    if (navbarSubmenu) {
      const listClass = navbarSubmenu.classList
      if (listClass.contains('expanded')) {
        navbarSubmenu.classList.remove('expanded')
      } else {
        navbarSubmenu.classList.add('expanded')
      }
    }
  }

  useEffect(() => {
    width < 767 && setIsMobile(true)
    submenu && setListSubmenu(submenu)
  }, [])

  //#endregion
  return (
    <div className="navbar-menu-item-container">
      {!isMobile ? (
        <>
          <div className={classes} onClick={() => (onClick ? onClick() : {})}>
            <div className="navbar-menu-item__title">
              {title}
              {listSubmenu && <Icon path={mdiMenuDown} size={0.8} />}
            </div>
          </div>
          {listSubmenu && (
            <div className="navbar-submenu">
              {listSubmenu.map((item: any, index: number) => (
                <div
                  onClick={() => (item.click ? item.click() : {})}
                  key={index}
                  className="navbar-submenu-item">
                  {item.title}
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          {!listSubmenu ? (
            <div className={classes} onClick={() => (onClick ? onClick() : {})}>
              <div className="navbar-menu-item__title">{title}</div>
            </div>
          ) : (
            <div
              className={`${classes} collapsible`}
              onClick={(e) => expandCollapsible(e)}>
              <div className="navbar-menu-item__title">
                {title}
                {listSubmenu && <Icon path={mdiMenuDown} size={0.8} />}
              </div>

              <div className="navbar-submenu-mobile">
                {listSubmenu.map((item: any, index: number) => (
                  <div
                    onClick={() => (item.click ? item.click() : {})}
                    key={index}
                    className={`navbar-submenu-item ${
                      pathname == item.path ? 'navbar-submenu-item--active' : ''
                    }`}>
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default NavbarItem

//#endregion
