//#region IMPORT
import React, { useEffect, useState } from 'react'
import HeaderIllustration from '../../../../assets/danatunai_header_illustration.png'
import './DanaTunaiHeader.scss'
import { externalLink, useWindowDimensions } from '../../../wrapper/utils'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DanaTunaiHeader = () => {
  const { width } = useWindowDimensions()
  const [ajukanLink, setAjukanLink] = useState<any>()

  useEffect(() => {
    setAjukanLink(
      'https://play.google.com/store/apps/details?id=com.dipostar.starx',
    )
    width < 992 &&
      setAjukanLink('https://starxperience.id/deep-link/dana-tunai')
  }, [])

  return (
    <div className="header-dana-tunai">
      <div className="header-dana-tunai__bg-color"></div>
      <div className="header-dana-tunai__content">
        <div className="header-dana-tunai__text">
          <div className="header-dana-tunai__title">
            <b>Dana Tunai</b> untuk Segala Kebutuhan Anda!
          </div>
          <button
            onClick={() => {
              externalLink(ajukanLink, 'deep-link-redirect')
            }}
            className="header-dana-tunai__button">
            Ajukan dalam Aplikasi
          </button>
        </div>
        <div className="header-dana-tunai__illustration">
          <img src={HeaderIllustration} />
        </div>
      </div>
    </div>
  )
}

export default DanaTunaiHeader
