/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'
import { format } from 'date-fns'
import { Env } from '../../config/Config'
import {
  ReviseBpkbOwner,
  UpdateBpkbOwnerProps,
  UpdateBpkbOwnerSpouseProps,
} from './types'

const http = axios.create({
  baseURL: Env.api_services.danatunai,
  headers: {
    'Content-type': 'application/json',
  },
})
const getTransaction = (params: any) => {
  return http.get('/transaction', {
    params: {
      include_draft: params.include_draft,
    },
  })
}

const getBpkbOwnerDetail = ({ ...params }: { form_token: string }) => {
  return http.get(`/${params.form_token}/bpkb_owner`)
}

const updateBpkbOwner = ({
  data,
  ...params
}: {
  form_token: string
  data: UpdateBpkbOwnerProps
}) => {
  const datas = {} as any
  const dataKey = [
    'bpkb_owner',
    'email',
    'phone',
    'date_of_birth',
    'married_status',
    'identity_number',
    'identity_type',
    'id',
    'documents',
  ]
  for (const [key, value] of Object.entries(data)) {
    if (dataKey.includes(key)) {
      datas[key] = value
      if (key === 'phone') datas[key] = `0${value}`
    }
    if (key === 'date_of_birth')
      datas[key] = format(new Date(value), 'yyyy-MM-dd')
  }
  return http
    .put(`/${params.form_token}/bpkb_owner`, datas)
    .catch((err) => err.response)
}

const updateBpkbOwnerSpouse = ({
  data,
  ...params
}: {
  form_token: string
  data: UpdateBpkbOwnerSpouseProps
}) => {
  const datas = {} as any
  const dataKey = [
    'bpkb_owner',
    'email',
    'phone',
    'date_of_birth',
    'married_status',
    'identity_number',
    'id',
    'documents',
  ]
  for (const [key, value] of Object.entries(data)) {
    if (dataKey.includes(key)) {
      datas[key] = value
      if (key === 'phone') datas[key] = `0${value}`
    }
    if (key === 'date_of_birth')
      datas[key] = format(new Date(value), 'yyyy-MM-dd')
  }
  return http
    .put(`/${params.form_token}/bpkb_owner_spouse`, datas)
    .catch((err) => err.response)
}

const reviseBpkbOwner = ({
  data,
  ...params
}: {
  form_token: string
  data: ReviseBpkbOwner
}) => {
  return http
    .put(`/${params.form_token}/update_revised_document`, data)
    .catch((err) => err.response)
}

const createAgreementActivationDoc = ({
  data,
  ...params
}: {
  customer_id: string
  activation_id: number
  data: ReviseBpkbOwner
}) => {
  return http
    .post(
      `/agreement_activation/${params.customer_id}/activation/${params.activation_id}/document/`,
      data,
    )
    .catch((err) => err.response)
}

const DanatunaiService = {
  getTransaction,
  getBpkbOwnerDetail,
  updateBpkbOwner,
  updateBpkbOwnerSpouse,
  reviseBpkbOwner,
  createAgreementActivationDoc,
}
export default DanatunaiService
