//#region IMPORT
import React from 'react'
import classnames from 'classnames'
import t from '../../../../lang'
import Icon from '@mdi/react'
import { PATH } from '../../../wrapper/constants/TypesWrapper'
import {
  mdiGooglePlay as iconPlayStore,
  mdiApple as iconAppStore,
} from '@mdi/js'
import './DownloadApp.scss'

import Iphone1 from '../../../../assets/iphone_1.png'
import Iphone2 from '../../../../assets/iphone_2.png'
import Iphone3 from '../../../../assets/iphone_3.png'

import { Button } from '../../../../components'
import { externalLink } from '../../../wrapper/utils'
//#endregion

//#region MAIN COMPONENT
//#region INTERFACE
type Props = {
  className?: string
  id: string
  title?: string
  description?: string
}
//#endregion

const DownloadApp: React.FC<Props> = ({
  className,
  id,
  title,
  description,
}: Props) => {
  //#region CLASSES
  const classes = classnames('download', { className })
  //#endregion

  const imageDownloadList = [Iphone1, Iphone2, Iphone3]

  return (
    <div id={id} className={classes}>
      <div className="download__title">{title}</div>
      <div className="download__description">{description}</div>
      <div className="download-image">
        {imageDownloadList.map((item, i) => (
          <div key={`item--${i}`} className="download-image__item">
            <img src={item} alt="" />
          </div>
        ))}
      </div>
      <div className="download-action">
        <Button
          elId="ButtonAndroidDownloadApp"
          isRounded
          text={t('Play Store')}
          iconLeft={<Icon path={iconPlayStore} />}
          onClick={() => {
            externalLink(PATH.PLAY_STORE, 'PLAYSTORE')
          }}
        />
        <Button
          elId="ButtonIOSDownloadApp"
          isRounded
          text={t('App Store')}
          iconLeft={<Icon path={iconAppStore} />}
          onClick={() => {
            externalLink(PATH.APP_STORE, 'APPSTORE')
          }}
        />
      </div>
    </div>
  )
}

export default DownloadApp

//#endregion
