import React, { useCallback, useEffect, useState } from 'react'
import { Header, OurService } from '../../components'
import FooterPromo from '../../../promo/components/FooterPromo'
import './Homepage.scss'
import t from '../../../../lang'

import { ServiceList, Slider } from '../../constants'
import servicesJSON from '../../entity/starx_services.json'
import { useDocumentTitle } from '../../../wrapper/utils'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { bannerSelector, getBanner } from '../../../../redux/banner'
import { Banner } from '../../../../services/blogService/types'
import { format } from 'date-fns'

const Homepage: React.FC = () => {
  const [servicesList, setServicesList] = useState<ServiceList[]>([])
  const { banner, loading } = useAppSelector(bannerSelector)

  useDocumentTitle('Homepage')
  const dispatch = useAppDispatch()
  const bannerFetch = useCallback(async () => {
    dispatch(
      getBanner({
        status: 'published',
        current_date: format(new Date(), 'yyyy-MM-dd'),
      }),
    )
  }, [banner])

  useEffect(() => {
    bannerFetch()
  }, [])

  useEffect(() => {
    setServicesList(servicesJSON)
  }, [])

  return (
    <div className="homepage">
      <Header.Carousel
        loading={loading}
        config={{
          ...(!loading && {
            loop: banner?.length > 0,
            navigation: banner?.length > 0,
          }),
        }}
        sliders={
          !loading && banner?.length > 0
            ? [
                {
                  image_url: {
                    desktop: './images/home/default_slide_web.jpg',
                    mobile: './images/home/default_slide_mobile.jpg',
                  },
                },
                ...banner.map((item: Banner) => {
                  const getImage = (type_code: 'cms' | 'mobile') => {
                    const result = item.post_images.find(
                      (v) => v.image_types.code === type_code,
                    )
                    return result?.image_url
                  }
                  const slider: Slider = {
                    hyperlink: item.hyperlink,
                    image_url: {
                      desktop:
                        getImage('cms') ??
                        './images/home/default_slide_web.jpg',
                      mobile:
                        getImage('mobile') ??
                        './images/home/default_slide_mobile.jpg',
                    },
                  }
                  return slider
                }),
              ]
            : [
                {
                  image_url: {
                    desktop: './images/home/default_slide_web.jpg',
                    mobile: './images/home/default_slide_mobile.jpg',
                  },
                },
              ]
        }
      />
      <OurService
        title={t('Star Xperience Service')}
        serviceList={servicesList}
      />
      <FooterPromo
        type={2}
        id="promo__footer"
        title={t('Apalagi yang Anda tunggu?')}
        description={t(
          'Segera jadi bagian dari StarXperience dan dapatkan banyak manfaat untuk menunjang kebutuhan Anda.',
        )}></FooterPromo>
    </div>
  )
}

export default Homepage
