//#region IMPORT
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '../../../../components'
import t from '../../../../lang'
import { PATH } from '../../../wrapper/constants'

import './ErrorContent.scss'
//#endregion

//#region INTERFACE
export interface Props {
  illustration: string
  title: string
  description?: string
  buttonText?: string
  buttonOnClick?(): void
}
//#endregion

//#region MAIN
export const ErrorContent: React.FC<Props> = ({
  illustration,
  title,
  description,
  buttonText,
  buttonOnClick,
}: Props) => {
  const history = useHistory()

  return (
    <div className="error-content">
      <div className="error-content__illustration">
        <img src={illustration} />
      </div>
      <div className="error-content__title">{title}</div>
      {description && (
        <div className="error-content__description">{description}</div>
      )}
      <div className="error-content__action">
        <Button
          elId="ButtonErrorContent"
          variant="outline"
          onClick={buttonOnClick || (() => history.push(PATH.HOME))}
          text={buttonText || t('Back to Home')}
        />
      </div>
    </div>
  )
}
//#endregion
