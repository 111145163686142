//#region IMPORT
import React, { useEffect, useState } from 'react'
import './Accordion.scss'
import Collapsible from 'react-collapsible'
import AccordionHeader from './AccordionHeader'
import { mdiChevronDown } from '@mdi/js'
import parse from 'html-react-parser'

interface Props {
  removedEl?: string
  datas: {
    title: string
    content: string
  }[]
}

const Accordion: React.FC<Props> = ({ removedEl, datas }: Props) => {
  const [promoContent, setPromoContent] = useState(datas)
  useEffect(() => {
    setPromoContent(datas)
  }, [datas])

  // used to : Remove any <h2></h2> tag from the string
  const cleanText = (descText: string, removedEl: string) => {
    const newRegex = new RegExp(`<${removedEl}.*?>.*?</${removedEl}>`, 'gi')
    const cleanedText = descText.replace(newRegex, '')
    return cleanedText
  }

  return (
    <div className="accordion-container">
      {promoContent.length > 0 &&
        promoContent.map((item: any, index: any) => (
          <Collapsible
            key={`collapsible-${index}`}
            open={index == 0 ? true : false}
            trigger={
              <AccordionHeader title={item.title} icon={mdiChevronDown} />
            }>
            {removedEl
              ? parse(cleanText(item.content, removedEl))
              : parse(item.content ?? '-')}
          </Collapsible>
        ))}
    </div>
  )
}

export default Accordion
