//#region IMPORT
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { CardPromo } from '../CardPromo'
import { useWindowDimensions } from '../../../wrapper/utils'
import { Loading } from '../../../../components'

import './OtherPromo.scss'

interface Props {
  isLoading: boolean
  otherPromoData: any
  totalOtherPromo: number
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const OtherPromo: React.FC<Props> = ({
  isLoading,
  otherPromoData,
  totalOtherPromo,
}: Props) => {
  const { width } = useWindowDimensions()
  const [displayStack, setDisplayStack] = useState(true)

  useEffect(() => {
    if (width < 767) {
      setDisplayStack(true)
    } else if (width <= 992 && width > 767) {
      setDisplayStack(false)
    }
  }, [])

  const history = useHistory()
  const seeAll = () => {
    history.push('/promo')
  }

  return (
    <div className="other-promo">
      <div className="other-promo__header">
        <h2>
          <span className="highlight__heading">Promo </span>
          Lainnya
        </h2>
        {totalOtherPromo >= 4 && (
          <button
            type="button"
            className="see-all__link"
            onClick={() => {
              seeAll()
            }}>
            Lihat Semua Promo
          </button>
        )}
      </div>
      {otherPromoData.length == 0 && isLoading ? (
        <Loading className="other-promo-loading" />
      ) : (
        <div className="other-promo__content">
          {otherPromoData &&
            otherPromoData.map((item: any, index: number) => (
              <CardPromo
                key={`other-promo-${index}`}
                data={item}
                isStacked={displayStack}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default OtherPromo
