//#region IMPORT
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import './CardPromo.scss'
import DateRange from '../DateRange'
import BranchList from '../BranchList'
import parse from 'html-react-parser'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Icon from '@mdi/react'
import { mdiMapMarker, mdiCalendar, mdiArrowRightThin } from '@mdi/js'

//#region INTERFACE
export interface Props {
  isLoading?: boolean
  data?: any
  className?: string
  isStacked: boolean
}

export interface SkeletonProps {
  isStacked: boolean
}
//#endregion

//#region MAIN
export const CardPromo: React.FC<Props> = ({
  data,
  className,
  isStacked,
}: Props) => {
  const classes = classNames('card', className)
  // eslint-disable-next-line no-unused-vars
  const [imageMobile, setImageMobile] = useState('../images/image-empty.svg')
  const [imageDesktop, setImageDesktop] = useState('../images/image-empty.svg')
  const [placeholderImg] = useState('../images/image-empty.svg')

  const generateSlug = (idPromo: string, promoTitle: string) => {
    const getMainTitle = promoTitle.toLowerCase().split('-')[0].trim()
    const splitTitle = getMainTitle.split(' ')
    const getTitle = encodeURIComponent(splitTitle.slice(0, 4).join('-'))
    const finalTitle = `/promo/${getTitle}-${idPromo}`
    return finalTitle
  }

  const updateImage = (images: any) => {
    let p: any = {}
    for (p of images) {
      if (p['image_type_id'] == 2) {
        setImageMobile(p.image_url)
      } else {
        setImageDesktop(p.image_url)
      }
    }
  }

  const history = useHistory()
  const viewPromo = (promoLink: string) => {
    history.push(promoLink)
  }

  useEffect(() => {
    data?.post_images
      ? updateImage(data.post_images)
      : updateImage(placeholderImg)
  }, [])

  // used to :
  //   1. Clean any <figure></figure> tag from the string
  //   2. Replace h1-h6 tag with <p> tag
  const cleanDescription = (descText: string) => {
    const cleanedText = descText
      ?.replace(/<figure.*?>.*?<\/figure>/gi, '')
      ?.replace(/<ol.*?>.*?<\/ol>/gi, '')
      ?.replace(/<ul.*?>.*?<\/ul>/gi, '')
      ?.replace(/<h[1-6].*?>/gi, '<p>')
      ?.replace(/<\/h[1-6].*?>/gi, '</p>')
    return cleanedText ?? ''
  }

  return (
    <div
      onClick={() => {
        viewPromo(generateSlug(data.id, data.title))
      }}
      className={classes}>
      <div className={`card__promo__container ${isStacked ? 'stacked' : ''}`}>
        <div
          className={`promo__image__container ${isStacked ? 'fullwidth' : ''}`}>
          <img loading="lazy" src={imageDesktop} alt="promo_image" />
        </div>
        <div className="promo__content">
          <div className="promo__body">
            <h3 className={`promo__title ${isStacked ? 'clamped' : ''}`}>
              {data.title.split('-')[0].trim()}
            </h3>
            <div className="promo__desc">
              {parse(cleanDescription(data.description))}
            </div>
          </div>
          <div className={`promo__card__footer ${isStacked ? 'stacked' : ''}`}>
            <div
              className={`promo__card__footer--info ${
                isStacked ? 'stacked' : ''
              }`}>
              <div className="promo__footer--info">
                <Icon path={mdiMapMarker} size={0.8}></Icon>
                <span className={`${isStacked ? 'clamped' : ''}`}>
                  <BranchList branches={data.branch_area} displayFull={false} />
                </span>
              </div>
              <div className="promo__footer--info">
                <Icon path={mdiCalendar} size={0.8}></Icon>
                <span>
                  <DateRange start={data.start_date} end={data.end_date} />
                </span>
              </div>
            </div>
            <div className="promo__card__footer--arrow">
              <Icon path={mdiArrowRightThin} size={1}></Icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
//#endregion MAIN

//#region CardPromo Skeleton
export const CardPromoSkeleton: React.FC<SkeletonProps> = ({
  isStacked,
}: SkeletonProps) => {
  return (
    <div className="card">
      <div className={`card__promo__container ${isStacked ? 'stacked' : ''}`}>
        <div
          className={`promo__image__container ${isStacked ? 'fullwidth' : ''}`}>
          <Skeleton width={140} height={140} />
        </div>
        <div className="promo__content">
          <div className="promo__body">
            <h3 className={`promo__title ${isStacked ? 'clamped' : ''}`}>
              <Skeleton />
            </h3>
            <div className="promo__desc">
              <Skeleton count={2} />
            </div>
          </div>
          <div className={`promo__card__footer ${isStacked ? 'stacked' : ''}`}>
            <div
              className={`promo__card__footer--info ${
                isStacked ? 'stacked' : ''
              }`}>
              <div className="promo__footer--info">
                <Icon path={mdiMapMarker} size={0.8}></Icon>
                <span className={`${isStacked ? 'clamped' : ''}`}>
                  <Skeleton width={150} />
                </span>
              </div>
              <div className="promo__footer--info">
                <Icon path={mdiCalendar} size={0.8}></Icon>
                <span>
                  <Skeleton width={200} />
                </span>
              </div>
            </div>
            <div className="promo__card__footer--arrow">
              <Icon path={mdiArrowRightThin} size={1}></Icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
//#endregion

export const CardPromoSkeletonContainer: React.FC<Props> = ({
  isStacked,
}: Props) => {
  return (
    <div className="card-promo-skeleton-container">
      <CardPromoSkeleton isStacked={isStacked} />
      <CardPromoSkeleton isStacked={isStacked} />
      <CardPromoSkeleton isStacked={isStacked} />
    </div>
  )
}
