/* eslint-disable */
export default [
  //#region GENERAL
  {
    en: 'See More',
    id: 'Selengkapnya',
  },
  {
    en: 'Login',
    id: 'Masuk',
  },
  //#endregion

  //#region NAVBAR
  {
    en: 'Home',
    id: 'Beranda',
  },
  {
    en: 'Join as a partner',
    id: 'Gabung Jadi Mitra',
  },
  {
    en: 'Services',
    id: 'Layanan',
  },
  //#endregion

  //#region HEADER
  {
    en: 'We build services for help you get past obstacles',
    id: 'Kami membangun layanan untuk membantumu melewati halangan',
  },
  {
    en:
      'As a form of ease of service for Dipo Star Finance customers digitally throughout Indonesia',
    id:
      'Sebagai bentuk kemudahan pelayanan bagi customer Dipo Star Finance secara digital di seluruh Indonesia',
  },
  {
    en: 'Expand the market and increase turnover without wasting budget',
    id: 'Perluas market dan tingkatkan omzet tanpa ngehamburin budget',
  },
  {
    en:
      'Reach more customers and get various conveniences by becoming a Star Xperience Partner',
    id:
      'Jangkau lebih banyak pelanggan dan dapatkan berbagai kemudahan dengan menjadi Mitra Star Xperience',
  },
  {
    en: 'We built a service to help you get past the barriers',
    id: 'Kami membangun layanan untuk membantumu melewati halangan',
  },
  //#endregion

  //#region FEATURES
  {
    en: 'What do you get?',
    id: 'Apa yang di dapat?',
  },
  {
    en:
      'Currently, the Star Xperience application has interesting features to use',
    id:
      'Saat ini aplikasi Star Xperience memiliki fitur-fitur yang menarik untuk digunakan',
  },
  //#endregion

  //#region OUR SERVICE
  {
    en: 'Star Xperience Service',
    id: 'Layanan Star Xperience',
  },
  {
    en: 'Why Star Xperience?',
    id: 'Mengapa Star Xperience?',
  },
  //#endregion

  //#region DOWNLOAD APP
  {
    en: 'Download Now',
    id: 'Download Sekarang',
  },
  {
    en:
      'Download the Star Xperience application and get various benefits to support your needs.',
    id:
      'Download aplikasi Star Xperience dan dapatkan berbagai manfaat untuk menunjang kebutuhan Anda.',
  },
  //#endregion

  //#region JOIN US
  {
    en: 'Interested in Partnering with Star Xperience?',
    id: 'Tertarik Bermitra dengan Star Xperience?',
  },
  {
    en: 'Register your bussiness right now.',
    id: 'Daftarkan segera usaha Anda.',
  },
  {
    en: 'Join Now',
    id: 'Gabung Sekarang',
  },
  //#endregion

  //#region PRODUCT SERVICE
  {
    en: 'Our Services:',
    id: 'Beberapa Layanan Kami',
  },
  //#endregion

  //#region FOOTER
  {
    en: 'About Us',
    id: 'Tentang Kami',
  },
  {
    en: 'Address',
    id: 'Alamat',
  },
  {
    en:
      'Star Xperience is a brand owned by PT Dipo Star Finance. Registered at the Directorate General of Intellectual Property of the Republic of Indonesia.',
    id:
      'Star Xperience adalah merek milik PT Dipo Star Finance. Terdaftar pada Direktorat Jendral Kekayaan Intelektual Republik Indonesia.',
  },
  {
    en:
      'PT Dipo Star Finance is licensed and supervised by the Financial Services Authority',
    id:
      'PT Dipo Star Finance berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK)',
  },
  {
    en: 'Follow Us on Social Media',
    id: 'Ikuti Kami di Media Sosial',
  },
  {
    en: 'Join Us',
    id: 'Gabung',
  },
  {
    en: 'Service Provider',
    id: 'Penyedia Layanan',
  },
  {
    en: 'Contact Halo DSF',
    id: 'Hubungi Halo DSF',
  },
  {
    en: 'Help',
    id: 'Bantuan',
  },
  {
    en: 'Contact',
    id: 'Hubungi',
  },
  //#endregion

  //#region FOOTER PROMO
  {
    en: 'What are You waiting for?',
    id: 'Apalagi yang Anda tunggu?',
  },
  {
    en:
      'Be a part of StarXperience and get benefits to support your needs.',
    id:
      'Segera jadi bagian dari StarXperience dan dapatkan banyak manfaat untuk menunjang kebutuhan Anda.',
  },
  //#endregion

  //#region DANA TUNAI
  {
    en: '<b>Dana Tunai</b> for All Your Needs!',
    id: '<b>Dana Tunai</b> untuk Segala Kebutuhan Anda!'
  },
  {
    en: 'Apply in App',
    id: 'Ajukan dalam Aplikasi',
  },
  //#endregion

  //#region ERROR PAGE
  {
    en: 'Back to Home',
    id: 'Ke Halaman Utama',
  },
  {
    en: 'Page Not Found :(',
    id: 'Halaman tidak ditemukan :(',
  },
  {
    en: 'Internal Server Error :(',
    id: 'Terjadi Kesalahan Server : (',
  },
  //#endregion
]
