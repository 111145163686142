import { createAsyncThunk } from '@reduxjs/toolkit'
import BlogService from '../../services/blogService/http'
import { PromoParams } from '../../services/blogService/types'

export const getPromo = createAsyncThunk(
  'banner',
  async (params: PromoParams) => {
    const response = await BlogService.getPromo(params).catch(
      (err) => err.response,
    )
    return response.data.data
  },
)
