//#region IMPORT
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ImageBPKBThankyou } from '../../../../assets/icon'
import { Button } from '../../../../components'
import { PATH } from '../../../wrapper/constants'
import { useDocumentTitle } from '../../../wrapper/utils'
import './BPKBThankYou.scss'
//#endregion

//#region MAIN
const BPKBThankYou: React.FC = () => {
  useDocumentTitle('BPKB Owner Thank You')
  const { state: data }: any = useLocation()
  const history = useHistory()

  //#region HANDLE ERROR
  !data && history.push(PATH.PAGE_404)
  //#endregion
  return (
    <div className="bpkb-thankyou">
      <div className="bpkb-thankyou__content">
        <ImageBPKBThankyou />
        <h3>Terima Kasih!</h3>
        <p>
          Proses pengisian informasi pendukung untuk pengajuan dana tunai atas
          nama <span>{data?.name}</span> telah kami terima.
        </p>
        <hr />
        <p>Untuk info lebih lanjut, Anda dapat menghubungi:</p>
        <a href="tel:+628041235235">
          <h4>Telepon: 08041235235</h4>
        </a>
        <a href="https://wa.me/628138005850">
          <h4>Whatsapp: 08138005850</h4>
        </a>
        <a href="mailto: halodsf@dipostar.com">
          <h4>Email: halodsf@dipostar.com</h4>
        </a>

        <Button
          elId="ButtonBackToBeranda"
          text="Kembali ke Beranda"
          color="secondary"
          onClick={() => {
            history.replace(PATH.HOME)
            history.go(0)
          }}
        />
      </div>
    </div>
  )
}

export default BPKBThankYou
//#endregion
