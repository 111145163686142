//#region IMPORT
import React from 'react'
import classnames from 'classnames'
import './JoinUs.scss'
import { Button } from '../../../../components'
import t from '../../../../lang'
import { PATH } from '../../../wrapper/constants'
import { externalLink } from '../../../wrapper/utils'
import { Env } from '../../../../config/Config'
//#endregion

//#region MAIN COMPONENT
//#region INTERFACE
type Props = {
  className?: string
  id?: string
  title?: string
  description?: string
}
//#endregion

const JoinUs: React.FC<Props> = ({
  className,
  id,
  title,
  description,
}: Props) => {
  //#region CLASSES
  const classes = classnames('join', `${className}`)
  //#endregion

  return (
    <div id={id} className={classes}>
      <div className="join__title">{title}</div>
      <div className="join__description">{description}</div>
      <div className="join__content">
        <Button
          elId="ButtonJoinPartner"
          isRounded
          text={t('Join Now')}
          onClick={(): void =>
            externalLink(Env.base_url + PATH.REGISTER_SP, 'REGISTRATION')
          }
        />
      </div>
    </div>
  )
}

export default JoinUs

//#endregion
