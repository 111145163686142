//#region IMPORT
import React from 'react'
import classNames from 'classnames'
import t from '../../../../lang'
import './ProductServiceItem.scss'
import Icon from '@mdi/react'
import { mdiArrowRightThinCircleOutline as CircleArrowLeft } from '@mdi/js'
//#endregion

type Props = {
  className?: string
  imageUrl1?: string
  imageUrl2?: string
  title?: string
  description?: string
  serviceItem?: string[]
}

const ProductServiceItem: React.FC<Props> = ({
  className,
  imageUrl1,
  imageUrl2,
  title,
  description,
  serviceItem,
}: Props) => {
  //#region CLASSES
  const classes = classNames('product-service-item', className)
  //#endregion

  return (
    <div className={classes}>
      <div className="product-service-item__image">
        <div className="product-service-item__image-wrapper">
          {imageUrl1 && <img src={imageUrl1} alt="" />}
          {imageUrl2 && <img src={imageUrl2} alt="" />}
        </div>
      </div>
      <div className="product-service-item__title">{title && title}</div>
      <div className="product-service-item__description">
        {description && description}
      </div>
      {serviceItem?.length !== 0 && (
        <div className="product-service-item__subtitle">
          {t('Layanan yang disediakan di antaranya:')}
        </div>
      )}
      {serviceItem && serviceItem?.length !== 0 && (
        <div className="product-service-item__content">
          {serviceItem.map((item, index) => (
            <div key={`service-item-${index}`}>
              <Icon path={CircleArrowLeft} size={1} /> <span>{item}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ProductServiceItem
