import { createReducer } from '@reduxjs/toolkit'
import { getPromo } from './actions'

const initialState: any = {
  promo: [],
  loading: true,
}
export const promoReducer = createReducer(initialState, (builder) => {
  // GET PROMO
  builder.addCase(getPromo.pending, (state) => {
    state.loading = true
  })
  builder.addCase(getPromo.fulfilled, (state, { payload }) => {
    state.promo = payload
    state.loading = false
  })
  builder.addCase(getPromo.rejected, (state) => {
    state.loading = false
  })
})

export default promoReducer
