//#region IMPORT
import React, { useCallback, useEffect, useState } from 'react'
import Axios from 'axios'
import {
  ServiceProviderList,
  SERVICE_CATEGORY_API,
  SERVICE_PROVIDER_API,
} from '../../constants'
import './ServiceProviderAll.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ServiceProviderItem } from '../../components'
import { Loading } from '../../../../components'
import t from '../../../../lang'
import { useDocumentTitle } from '../../../wrapper/utils'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
//#endregion

//#region MAIN
export const ServiceProviderAll: React.FC = () => {
  useDocumentTitle('Service Provider')
  const [service, setService] = useState<ServiceProviderList[]>([])
  const [title, setTitle] = useState('Semua Penyedia Layanan')
  const [loading, setLoading] = useState(false)
  const [length, setLength] = useState(0)
  const [next, setNext] = useState(0)

  const location = useLocation()
  const categoryParams = queryString.parse(location.search)

  const getService = () => {
    return Axios.get(SERVICE_PROVIDER_API, {
      params: {
        limit: 12,
        offset: next,
        service_type_id: categoryParams.category,
      },
    })
  }

  const getServiceType = () => {
    return Axios.get(`${SERVICE_CATEGORY_API}/${categoryParams.category}`)
  }

  const serviceListFetch = useCallback(async () => {
    setLoading(true)
    const response = await getService()
    const data = response.data['data']
    setService([...service, ...data])
    setLength(response.data['current_total_item'])
    setLoading(false)
  }, [next])

  const serviceTypeFetch = useCallback(async () => {
    const response = await getServiceType()
    setTitle(`Penyedia Layanan - ${response.data['data']['name']}`)
  }, [])

  useEffect(() => {
    serviceListFetch()
    categoryParams.category !== undefined && serviceTypeFetch()
  }, [next])

  return (
    <div className="service-provider-all">
      <div className="service-provider-all__title">{t(title)}</div>
      <div className="service-provider-all__description">{`Ada ${length} Hasil.`}</div>
      {service.length < 1 && loading ? (
        <Loading className="service-provider-all__loading" />
      ) : (
        <InfiniteScroll
          className="service-provider-all__content"
          dataLength={service.length}
          next={() => setNext(next + 12)}
          hasMore={next < length}
          loader={<Loading className="service-provider-all__loadmore" />}>
          {service.map((item, index) => (
            <ServiceProviderItem
              key={`service-provider-item-${index}`}
              images={item.service_photos}
              name={item.name}
              categories={item.service_group_name}
              rating={item.rating}
              location={item.location}
            />
          ))}
        </InfiniteScroll>
      )}
    </div>
  )
}
//#endregion
