//#region IMPORT
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import {
  CardIcon,
  DateIcon,
  EmailIcon,
  UserIcon,
} from '../../../../assets/icon'
import { Button, Card, Input } from '../../../../components'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import './BPKBOwner.scss'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
  bpkbOwnerSelector,
  getBpkbOwnerDetail,
} from '../../../../redux/bpkbOwner'
import { parseISO } from 'date-fns'
import FileService from '../../../../services/fileService/http'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import DanatunaiService from '../../../../services/danaTunai/http'
import { useDocumentTitle, base64ToBlob } from '../../../wrapper/utils'
import { PATH } from '../../../wrapper/constants'
import { BPKBOwnerProps, MarriedStatus } from '../../constants/types'
//#endregion

//#region INTERFACE
type Values = {
  bpkb_owner: string
  email: string
  phone: string | number
  date_of_birth: string | Date
  married_status: string
  identity_number: string
  identity_type: string
  id: number
  documents: any[]
  FotoKTPBPKBOwner?: string
  FotoSelfieBPKBOwner?: string
  customer_id: string
  files?: {
    FotoKTPBPKBOwner: any
    FotoSelfieBPKBOwner: any
  }
}
type Allowed = 'FotoKTPBPKBOwner' | 'FotoSelfieBPKBOwner'
//#endregion

//#region MAIN
const validationSchema = Yup.object().shape({
  bpkb_owner: Yup.string().nullable().required('Nama harus diisi.'),
  identity_number: Yup.string()
    .nullable()
    .min(16, 'Silakan masukkan sesuai format NIK')
    .max(16)
    .required('NIK harus diisi.'),
  email: Yup.string()
    .nullable()
    .email('Silakan masukkan sesuai format email')
    .required('Email harus diisi.'),
  phone: Yup.string()
    .nullable()
    .min(8, 'Masukkan min. 8 karakter')
    .max(12)
    .required('No. Telepon harus diisi.'),
  date_of_birth: Yup.date()
    .nullable()
    .typeError('Masukkan tanggal lahir dengan format DD/MM/YYY.')
    .required('Tanggal lahir harus diisi.'),
  married_status: Yup.string().nullable().required('Status kawin harus diisi.'),
  FotoKTPBPKBOwner: Yup.string().nullable().required('Foto KTP harus diisi.'),
})

type Submitted = {
  data: BPKBOwnerProps
  token: string
  married_status: MarriedStatus
}

const BPKBOwner: React.FC = () => {
  useDocumentTitle('BPKB Owner')
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { bpkbOwnerDetail, loading } = useAppSelector(bpkbOwnerSelector)
  const detail: BPKBOwnerProps = bpkbOwnerDetail.data
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const [submitted, setSubmitted] = useState<Submitted>()
  const token = urlParams.get('token')
  const isMobile = urlParams.get('mobile')

  const [redirectSearchParam, setRedirectSearchParam] = useState('')
  const [searchParam, setSearchParam] = useState('')
  const allowed = ['FotoKTPBPKBOwner', 'FotoSelfieBPKBOwner']
  const [faceResult, setFaceResult] = useState('')
  const getDetectionResult = localStorage.getItem('BPKB_OWNER_LIVENESS_DATA')
  const detectionResult = getDetectionResult
    ? JSON.parse(getDetectionResult)
    : ''

  const getBpkbDetail = useCallback(async () => {
    dispatch(
      getBpkbOwnerDetail({
        form_token: token,
      }),
    )
  }, [bpkbOwnerDetail])

  useEffect(() => {
    getBpkbDetail()

    if (!isMobile) {
      setRedirectSearchParam(`?redirect_to=BPKB_OWNER&token=${token}`)
    } else {
      setSearchParam('?mobile=true')
      setRedirectSearchParam(
        `?redirect_to=BPKB_OWNER&token=${token}&mobile=${isMobile}`,
      )
    }
  }, [])

  //#region HANDLE ON BACK
  useEffect(() => {
    history.listen(() => {
      if (history.action === 'POP') {
        history.go(0)
      }
    })
  }, [history])

  !detail && !loading && history.push(PATH.PAGE_404)
  detail &&
    !detectionResult &&
    history.push({
      pathname: PATH.LIVENESS,
      search: redirectSearchParam,
    })
  //#endregion

  //#region INITIALIZE
  const getFilename = (key: string) => {
    return detail?.documents
      .filter((v) => v.document_group.code === key)
      .reduce((row) => row).filename
  }
  const initialValues: Values = {
    ...detail,
    email: detail?.email ?? '',
    married_status: detail?.married_status ?? '',
    bpkb_owner: detail?.bpkb_owner ?? '',
    phone: detail?.phone ? parseInt(detail?.phone) : '',
    date_of_birth: detail?.date_of_birth ? new Date(detail?.date_of_birth) : '',
    FotoKTPBPKBOwner: getFilename('FotoKTPBPKBOwner') ?? '',
    FotoSelfieBPKBOwner: getFilename('FotoSelfieBPKBOwner') ?? '',
  }
  //#endregion

  //#region FUNCTION
  const uploadFile = async (key: any, file: any) => {
    const res = await FileService.create({
      app_name: 'starx',
      profile_name: '3mb',
      tags: `${detail.bpkb_owner}_bpkb_${key}`,
      files: file,
    }).catch((err) => err.response)
    if (!res.data.status) {
      Swal.fire({
        title: 'Terjadi Kesalahan!',
        text: res.data.message,
        icon: 'error',
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    }
    return res.data.data
  }
  //#endregion

  //#region HANDLE SUBMIT
  const onSubmitClick = async (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>,
  ) => {
    const generatedSelfieBPKBOwner = new File(
      [base64ToBlob(faceResult)],
      'image.jpg',
      {
        type: 'image/jpeg',
      },
    )
    if (typeof values.files !== 'undefined') {
      values.files['FotoSelfieBPKBOwner'] = generatedSelfieBPKBOwner
    } else {
      values.files = {
        FotoKTPBPKBOwner: undefined,
        FotoSelfieBPKBOwner: generatedSelfieBPKBOwner,
      }
    }
    if (!values.files['FotoKTPBPKBOwner']) {
      delete values.files.FotoKTPBPKBOwner
    }

    if (values.files) {
      for (const [key, file] of Object.entries(values.files)) {
        const getFile = await uploadFile(key, file)
        const getDocument = values.documents
          .filter((d) => d.document_group.code === key)
          .reduce((obj, key) => {
            obj[key] = values.documents[key]
            return obj
          })

        values.documents = [
          ...values.documents.filter(
            (d) =>
              d.id !== getDocument.id &&
              allowed.includes(d.document_group.code),
          ),
          {
            id: getDocument.id,
            filename: getFile.file_url,
            document_group: {
              code: getDocument.document_group.code,
            },
          },
        ]
      }
    }

    if (!values.files) {
      values.documents = [
        ...values.documents.filter(
          (d) => d.id !== allowed.includes(d.document_group.code),
        ),
      ]
    }

    const resSubmit = await DanatunaiService.updateBpkbOwner({
      form_token: token ?? '',
      data: values,
    })
    if (resSubmit.status === 200) {
      localStorage.removeItem('BPKB_OWNER_LIVENESS_DATA')
      setSubmitted({
        data: detail,
        token: token ?? '',
        married_status: values.married_status as MarriedStatus,
      })
    } else {
      Swal.fire({
        title: 'Terjadi Kesalahan!',
        text: resSubmit.data.message,
        icon: 'error',
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (!result.isConfirmed) {
          history.go(0)
        }
      })
    }

    setSubmitting(false)
  }

  useEffect(() => {
    if (submitted?.married_status === 'MARRIED') {
      history.push({
        pathname: PATH.BPKB_OWNER_SPOUSE,
        search: searchParam,
        state: {
          form_token: submitted?.token,
        },
      })
    }
    if (submitted?.married_status === 'SINGLE') {
      history.push({
        pathname: PATH.BPKB_THANK_YOU,
        state: {
          name: submitted?.data.customer?.username,
        },
      })
    }
  }, [submitted])
  //#endregion

  return (
    <div className="bpkb-owner">
      <div className="bpkb-owner__title">BPKB - Data Pemilik</div>

      {!detail ? (
        <p className="bpkb-owner__content" style={{ textAlign: 'center' }}>
          {loading
            ? 'Loading...'
            : 'Data tidak ditemukan atau token tidak valid.'}
        </p>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitClick}>
          {({ isSubmitting, isValid }): ReactElement => {
            return (
              <Form>
                <Card title="Biodata Diri" className="bpkb-owner__content">
                  <Field
                    component={Input.Text}
                    name="bpkb_owner"
                    label="Nama"
                    placeholder="Silakan isi nama Anda"
                    className="form-item"
                    iconLeft={<UserIcon />}
                    autoComplete="off"
                  />
                  <Field
                    component={Input.Number}
                    name="identity_number"
                    label="NIK"
                    placeholder="Silakan isi NIK Anda"
                    className="form-item"
                    maxLength={16}
                    iconLeft={<CardIcon />}
                    autoComplete="off"
                  />
                  <Field
                    component={Input.Text}
                    name="email"
                    label="Email"
                    placeholder="Silakan isi email Anda"
                    className="form-item"
                    iconLeft={<EmailIcon />}
                    autoComplete="off"
                  />
                  <Field
                    component={Input.Phone}
                    name="phone"
                    label="No. Telepon"
                    placeholder="82123456789"
                    className="form-item"
                    maxLength={12}
                    iconLeft="+62"
                    autoComplete="off"
                  />
                  <Field
                    component={Input.DatePicker}
                    name="date_of_birth"
                    label="Tanggal Lahir"
                    placeholder="Silakan isi tanggal lahir Anda"
                    className="form-item"
                    maxLength={13}
                    iconLeft={<DateIcon />}
                    initialStartDate={
                      detail.date_of_birth ? parseISO(detail.date_of_birth) : ''
                    }
                    maxDate={new Date()}
                  />
                  <Field
                    component={Input.Radio}
                    name="married_status"
                    label="Status Perkawinan"
                    className="form-item"
                    options={[
                      {
                        label: 'Tidak Kawin',
                        value: 'SINGLE',
                      },
                      {
                        label: 'Kawin',
                        value: 'MARRIED',
                      },
                    ]}
                  />
                </Card>
                <Card title="Lampiran Dokumen" className="bpkb-owner__content">
                  <div className="input-image">
                    {detail.documents
                      .filter((d) => allowed.includes(d.document_group.code))
                      .map((document, i) => {
                        const label = {
                          FotoKTPBPKBOwner: 'KTP',
                          FotoSelfieBPKBOwner: 'Selfie',
                        }

                        let photoUrl = document.filename
                        let photoFromLiveness = false
                        if (
                          String(document.document_group.code) ==
                          'FotoSelfieBPKBOwner'
                        ) {
                          photoUrl = detectionResult?.face_2 ?? photoUrl

                          photoFromLiveness = true
                          setFaceResult(photoUrl)
                        }

                        return (
                          <Field
                            key={`document-item-${i}`}
                            component={Input.Image}
                            name={document.document_group.code}
                            imageUrl={photoUrl ?? ''}
                            label={
                              label[document.document_group.code as Allowed]
                            }
                            disableDelete={photoFromLiveness ? true : false}
                            className="input-image-item"
                          />
                        )
                      })}

                    <div className="input-image__legend">
                      <p>*Ukuran foto maksimal 3MB.</p>
                      <p>
                        *Format foto yang diperbolehkan adalah JPG, JPEG, dan
                        PNG
                      </p>
                    </div>
                  </div>
                </Card>
                <div className="bpkb-owner__footer">
                  <Button
                    type="submit"
                    elId="ButtonSubmitBPKBOwner"
                    text="Selanjutnya"
                    disabled={isSubmitting || !isValid}
                    loading={isSubmitting}
                    fullWidth
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

export default BPKBOwner
//#endregion
