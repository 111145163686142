/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Resizer from 'react-image-file-resizer'

export const resizeImage = (
  file: any,
  type: 'JPEG' | 'PNG' | 'WEBP',
  output?: 'file' | 'blob' | 'base64',
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      type,
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      output ?? 'base64',
    )
  })
