/**
 * Lang
 * Return string of desier lang
 * Currently still static
 */

type LangCode = 'en' | 'id'

export const lang = (): LangCode => {
  return 'id'
}

// Environtment configuration
export const Env: {
  base_url: string
  tracking_id: string
  gtm_id: string
  api: string
  location_service: string
  api_services: {
    file?: string
    danatunai?: string
    blog?: string
  }
} =
  process.env.REACT_APP_ENV === 'production'
    ? {
        base_url: 'https://starxperience.id',
        tracking_id: 'G-N7N2B7GN6D',
        gtm_id: 'GTM-WTV4ND5',
        api: 'https://starxperience.id/api',
        location_service: 'http://34.101.207.141',
        api_services: {
          file: 'https://starxperience.id/api/file',
          danatunai: 'https://starxperience.id/api/danatunai',
          blog: 'https://starxperience.id/api/blog',
        },
      }
    : process.env.REACT_APP_ENV === 'qa'
    ? {
        base_url: 'https://qastarx.dipostar.org',
        tracking_id: 'G-N7N2B7GN6D',
        gtm_id: 'GTM-WTV4ND5',
        api: 'https://qastarx.dipostar.org/api',
        location_service: 'http://34.101.207.141',
        api_services: {
          file: 'https://qastarx.dipostar.org/api/file',
          danatunai: 'https://qastarx.dipostar.org/api/danatunai',
          blog: 'https://qastarx.dipostar.org/api/blog',
        },
      }
    : process.env.REACT_APP_ENV === 'preprod'
    ? {
        base_url: 'https://preprodstarx.dipostar.org',
        tracking_id: 'G-N7N2B7GN6D',
        gtm_id: 'GTM-WTV4ND5',
        api: 'https://preprodstarx.dipostar.org/api',
        location_service: 'https://preprodstarx.dipostar.org/api/location',
        api_services: {
          file: 'https://preprodstarx.dipostar.org/api/file',
          danatunai: 'https://preprodstarx.dipostar.org/api/danatunai',
          blog: 'https://preprodstarx.dipostar.org/api/blog',
        },
      }
    : {
        base_url: 'https://devstarx.dipostar.org',
        tracking_id: 'G-N7N2B7GN6D',
        gtm_id: 'GTM-WTV4ND5',
        api: 'https://devstarx.dipostar.org/api',
        location_service: 'http://34.101.207.141',
        api_services: {
          file: 'https://qastarx.dipostar.org/api/file',
          danatunai: 'https://devstarx.dipostar.org/api/danatunai',
          blog: 'https://devstarx.dipostar.org/api/blog',
        },
      }
