import { createReducer } from '@reduxjs/toolkit'
import { getBpkbOwnerDetail } from './actions'

const initialState: any = {
  bpkbOwnerDetail: [],
  fileUpload: [],
  danaTunai: [],
  loading: true,
}
export const bpkbOwnerReducer = createReducer(initialState, (builder) => {
  // GET BPKB OWNER DETAIL
  builder.addCase(getBpkbOwnerDetail.pending, (state) => {
    state.loading = true
  })
  builder.addCase(getBpkbOwnerDetail.fulfilled, (state, { payload }) => {
    state.bpkbOwnerDetail = payload
    state.loading = false
  })
  builder.addCase(getBpkbOwnerDetail.rejected, (state) => {
    state.loading = false
  })
})

export default bpkbOwnerReducer
