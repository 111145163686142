//#region IMPORT
import React, { useEffect, useState } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import classnames from 'classnames'
import './Features.scss'

import { FeaturesList } from '../../constants'
import { useWindowDimensions } from '../../../wrapper/utils'
//#endregion

//#region MAIN COMPONENT
//#region INTERFACE
type Props = {
  className?: string
  id?: string
  title?: string
  description?: string
  featuresList: FeaturesList[]
}
//#endregion

SwiperCore.use([Navigation])

const Features: React.FC<Props> = ({
  className,
  id,
  title,
  description,
  featuresList,
}: Props) => {
  //#region CLASSES
  const classes = classnames('features', { className })
  //#endregion

  const { width } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    width < 992 ? setIsMobile(true) : setIsMobile(false)
  })

  //#region FEATURES ITEM
  const FeaturesItem = (
    imageUrl?: string,
    title?: string,
    label?: string,
    index?: number,
  ) => {
    return (
      <div key={index} className="features-item">
        <div className="features-item__image">
          {imageUrl && <img src={imageUrl} alt={`features-${index}`} />}
        </div>
        {title && <div className="features-item__title">{title}</div>}
        {label && <div className="features-item__label">{label}</div>}
      </div>
    )
  }
  //#endregion

  return (
    <div id={id} className={classes}>
      <div className="features__title">{title}</div>
      <div className="features__description">{description}</div>
      <div className="features__content">
        <Swiper
          navigation
          spaceBetween={isMobile ? 16 : 24}
          slidesPerView={1.15}
          breakpoints={{
            768: {
              slidesPerView: 2.5,
            },
            1200: {
              slidesPerView: 3.5,
            },
          }}>
          {featuresList.map((item, index) => (
            <SwiperSlide key={`feature-item${index}`}>
              {FeaturesItem(item.imageUrl, item.title, item.label, index)}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default Features

//#endregion
