import { Env } from '../../../config/Config'
import { PATH } from '../../wrapper/constants'

export const GET_SERVICE_PROVIDER = 'GET_SERVICE_PROVIDER'

//#region GENERAL
export const PROMO_API = `${Env.api}/blog/v2/promo`
export const FILTER_LIST = [
  {
    title: 'Semua',
    code: 'all',
    pathname: PATH.PROMO,
  },
  {
    title: 'Mobil Penumpang Baru',
    code: 'mobil_penumpang_baru',
    pathname: PATH.PROMO_NEW_CAR,
  },
  {
    title: 'Mobil Niaga Baru',
    code: 'mobil_niaga_baru',
    pathname: PATH.PROMO_TRADE_CAR,
  },
  {
    title: 'Dana Tunai',
    code: 'dana_tunai',
    pathname: PATH.PROMO_DANA_TUNAI,
  },
  {
    title: 'Modal Usaha',
    code: 'modal_usaha',
    pathname: PATH.PROMO_MODAL_USAHA,
  },
]
//#endregion
