//#region IMPORT
import React, { useEffect, useState } from 'react'
import { Header } from '../../../homepage/components'
import FooterPromo from '../../../promo/components/FooterPromo'
import t from '../../../../lang'
import './PrivacyPolicy.scss'
import parse from 'html-react-parser'

import { useDocumentTitle } from '../../../wrapper/utils'
import { Breadcrumb } from '../../../homepage/components/Breadcrumb'
import PrivacyPolicyContent from '../../entity/starx_privacypolicy_content.json'
import { mdiChevronRight } from '@mdi/js'
//#endregion

interface BreadcrumbInt {
  title: string
  link: string
  is_active: boolean
}

const PrivacyPolicy: React.FC = () => {
  useDocumentTitle(t('Kebijakan Privasi'))
  const [breadcrumbList, setBreadcrumbList] = useState<BreadcrumbInt[]>([])

  useEffect(() => {
    setBreadcrumbList([
      {
        title: 'Home',
        link: '/',
        is_active: false,
      },
      {
        title: t('Kebijakan Privasi'),
        link: '',
        is_active: true,
      },
    ])
  }, [])

  return (
    <div className="privacy_policy">
      <Header.Promo
        className="privacy_policy-header"
        title={t('Kebijakan Privasi')}
        description="StarXperience"></Header.Promo>

      <div className="privacy_policy-container">
        <Breadcrumb
          listPages={breadcrumbList}
          separator={mdiChevronRight}></Breadcrumb>

        <div className="privacy_policy-content">
          {parse(PrivacyPolicyContent[0]['content'])}
        </div>
      </div>

      <FooterPromo
        type={2}
        id="promo__footer"
        title={t('Apalagi yang Anda tunggu?')}
        description={t(
          'Segera jadi bagian dari StarXperience dan dapatkan banyak manfaat untuk menunjang kebutuhan Anda.',
        )}></FooterPromo>
    </div>
  )
}

export default PrivacyPolicy
