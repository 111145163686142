/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
//#region IMPORT
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../../../components'
import {
  PATH,
  DEEPLINK_LIST,
  META_TAG_LIST,
  EXCEPTIONAL_PROPERTYNAME,
} from '../../../wrapper/constants'
import { externalLink } from '../../../wrapper/utils'
import './DeepLink.scss'
//#endregion

//#region MAIN
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DeepLink: React.FC = () => {
  const ref = useRef<HTMLButtonElement>(null)
  const [envName, setEnvName] = useState(process.env.NODE_ENV)
  const [currentUrl, setCurrentUrl] = useState(window.location.href)

  const generateMetaContentValue = () => {
    const envEnum: any = {
      development: 'dev',
      qa: 'qa',
      preprod: 'preprod',
      production: '',
    }

    let androidPackageName = 'com.dipostar.starx'
    envEnum[envName] &&
      (androidPackageName = `com.dipostar.starx.${envEnum[envName]}`)

    return androidPackageName
  }

  const cleanMetaTag = () => {
    META_TAG_LIST.map((item) => {
      const metaEl = document.head.querySelector(
        `[property="${item.propertyName}"]`,
      )
      metaEl?.setAttribute('content', '')
    })
  }

  useEffect(() => {
    ref.current?.click()
    window.addEventListener('beforeunload', function () {
      META_TAG_LIST.map((item) => {
        const metaEl = document.head.querySelector(
          `[property="${item.propertyName}"]`,
        )
        metaEl?.setAttribute('content', '')
      })
    })

    if (currentUrl.includes('deep-link')) {
      const getDeeplinkTitle = currentUrl.substring(
        currentUrl.lastIndexOf('/') + 1,
      )
      if (DEEPLINK_LIST.includes(getDeeplinkTitle)) {
        META_TAG_LIST.map((item) => {
          if (!EXCEPTIONAL_PROPERTYNAME.includes(item.propertyName)) {
            const metaEl = document.head.querySelector(
              `[property="${item.propertyName}"]`,
            )
            metaEl?.setAttribute('content', item.content)
          }
        })

        const metaUrlValue = `starx://deep-link/${getDeeplinkTitle}`
        const androidMetaTagEl = document.head.querySelector(
          '[property="al:android:package"]',
        )
        const androidMetaUrlEl = document.head.querySelector(
          '[property="al:android:url"]',
        )
        const iosMetaUrlEl = document.head.querySelector(
          '[property="al:ios:url"]',
        )

        androidMetaUrlEl?.setAttribute('content', metaUrlValue)
        androidMetaTagEl?.setAttribute('content', generateMetaContentValue())
        iosMetaUrlEl?.setAttribute('content', metaUrlValue)
      } else {
        cleanMetaTag()
      }
    } else {
      cleanMetaTag()
    }
  }, [])
  return (
    <div className="deep-link">
      <Button
        ref={ref}
        elId="ButtonDeepLink"
        text="Install Aplikasi StarX-Mobile"
        onClick={() =>
          externalLink(PATH.DEEP_LINK_REDIRECT, 'deep-link-redirect', '_self')
        }
      />
    </div>
  )
}
//#endregion
