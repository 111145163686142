//#region IMPORT
import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'mui-tabs'
import './PromoTabs.scss'
import parse from 'html-react-parser'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export interface Props {
  datas: {
    title: string
    content: string
  }[]
}

export const PromoTabs: React.FC<Props> = ({ datas }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [promoContent, setPromoContent] = useState(datas)
  useEffect(() => {
    setPromoContent(datas)
  }, [datas])

  return (
    <>
      {promoContent.length > 0 && (
        <Tabs
          key="asd"
          value={selectedIndex}
          onChange={(value) => setSelectedIndex(value)}>
          {promoContent.map((item: any, index: any) => (
            <Tab key={`tab-${index}`} value={index} label={item.title} />
          ))}
        </Tabs>
      )}
      {promoContent.length > 0 && (
        <div className="tabs-content">
          {parse(datas[selectedIndex]['content'] ?? '-')}
        </div>
      )}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PromoTabsSkeleton = () => {
  return (
    <>
      <h2>
        <Skeleton width={300} />
      </h2>

      <p>
        <Skeleton count={15} />
      </p>
    </>
  )
}
