import { createAsyncThunk } from '@reduxjs/toolkit'
import DanatunaiService from '../../services/danaTunai/http'

export const getBpkbOwnerDetail = createAsyncThunk(
  'bpkbOwnerDetail',
  async (params: any) => {
    const response = await DanatunaiService.getBpkbOwnerDetail(params).catch(
      (err) => err.response,
    )
    return response.data
  },
)
